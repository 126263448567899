@use "src/styles/globals.scss" as *;

.cross-chain {
  margin-bottom: 60px;

  @include desktop {
    border-radius: 16px;
    margin-bottom: 80px;
  }

  &-top {
    @include centered-row;
    color: var(--color-white);
    justify-content: space-between;
    margin-bottom: 16px;

    @include desktop {
      margin-bottom: 28px;
    }

    &-fullscreen {
      cursor: pointer;
      transform: translateY(3px);
    }

    &-title {
      @include centered-row;
      @include text-roboto-subheading-500;
      gap: 8px;
      width: 100%;

      @include desktop {
        max-width: max-content;
      }

      svg {
        color: var(--color-gray-600);
      }

      & > a {
        @include button-ghost;
        margin-left: auto;

        @include desktop {
          margin-left: 8px;
        }
      }
    }

    &-download {
      display: none;

      @include desktop {
        display: flex;
      }
    }
  }

  &-options {
    align-items: center;
    display: grid;
    gap: 19px 0;
    grid-template-columns: 1fr 1fr;
    min-height: 56px;
    padding-bottom: 36px;
    width: 100%;

    @include desktop {
      background-color: var(--color-gray-950);
      border-radius: 16px 16px 0 0;
      gap: 24px;
      padding: 24px 24px 28px 24px;
    }

    & > .toggle-group {
      grid-column: span 2;
      max-width: 100%;

      @include desktop {
        grid-column: span 1;
      }

      & > .toggle-group-root {
        width: 100%;

        @include desktop {
          width: auto;
        }

        & .toggle-group-item {
          justify-content: center;
          width: 100%;
        }
      }
    }

    &-txsText {
      @include text-roboto-body-500;
      color: var(--color-white);
    }

    &-filters {
      @include centered-column;
      grid-column: span 2;
      gap: 32px;
      width: 100%;

      @include desktop {
        grid-column: span 1;
      }

      &-group {
        display: grid;
        gap: 16px;
        grid-template-columns: 1fr 1fr;

        @include desktop {
          @include centered-row;
          gap: 16px;
          justify-content: flex-end;
          flex-wrap: nowrap;
          width: inherit;
        }

        & .select__control {
          justify-content: center;

          @include desktop {
            justify-content: space-between;
          }
        }

        &-download {
          max-width: 100%;

          @include desktop {
            display: none;
          }
        }
      }

      @include desktop {
        @include centered-row;
        width: inherit;
      }
    }
  }

  &-chart {
    @include centered-column;
    min-height: 706px;
    position: relative;
    border-radius: 0 0 16px 16px;
    padding-bottom: 24px;

    @include desktop {
      background-color: var(--color-gray-950);
    }

    & .wormholescan-brand {
      bottom: 0;
      filter: grayscale(1);
      height: 34px;
      left: 0;
      margin: auto;
      min-height: unset;
      opacity: 0.2;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      user-select: none;
      width: 217px;
      z-index: 1;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      @include desktop {
        padding: 0 24px;
      }

      & > span {
        @include text-heading6;
        color: var(--color-gray-400);

        &.selected {
          color: var(--color-white);
        }
      }
    }

    &-container {
      display: flex;
      flex: 1;
      justify-content: space-between;
      min-height: 650px;
      position: relative;

      &-column {
        height: 100%;
        position: relative;
        width: 50%;
        z-index: 1;

        @include desktop {
          width: 25%;
        }

        &-item {
          appearance: none;
          background: none;
          border: none;
          color: inherit;
          font: inherit;
          margin: 0;
          text-align: start;

          align-items: center;
          cursor: pointer;
          display: grid;
          gap: 8px;
          grid-template-columns: auto auto auto auto;
          justify-content: flex-start;
          padding: 0 12px;
          position: absolute;
          transition: top 0.3s ease, height 0.3s ease;
          width: 100%;

          @include desktop {
            border-bottom: 1px solid var(--color-gray-900);
            grid-template-columns: 28px 5fr 1fr;

            &:hover {
              background-color: var(--color-white-03);
            }
          }

          @include bigDesktop {
            grid-template-columns: 28px 1.5fr 1fr 1fr;
          }

          &:last-child {
            border: none;
          }

          & > .blockchain-icon {
            height: 16px;
            width: 16px;

            @include desktop {
              height: 24px;
              width: 24px;
            }
          }

          & > .chain {
            @include text-roboto-body-500;
            opacity: 0.35;
            white-space: nowrap;

            @include desktop {
              opacity: 1;
            }
          }

          & > .separator {
            @include text-roboto-body-500;
            opacity: 0.35;
            white-space: nowrap;

            @include desktop {
              display: none;
              opacity: 1;
            }
          }

          & > .volume {
            @include centered-row;
            @include text-heading6;
            color: var(--color-gray-400);
            justify-self: flex-end;

            @include desktop {
              @include text-heading5;
              color: var(--color-white);
            }
          }

          & > .percentage {
            @include text-heading6;
            color: var(--color-gray-400);
            justify-self: end;

            @include desktop {
              @include centered-row;
              background-color: var(--color-gray-900);
              border-radius: 12px;
              box-shadow: 0px 4px 4px 0px var(--color-black-25);
              color: var(--color-white);
              height: 24px;
              margin-left: auto;
              padding: 0 8px;
            }
          }

          &-tooltip {
            @include centered-column;
            justify-content: flex-start;
            min-width: min-content;
            padding: 0 !important;
            transform: translateY(12px);
            width: calc(50vw - 16px);

            @include tablet {
              width: calc(50vw - 60px);
            }

            @include desktop {
              width: calc(25vw - 60px);
            }

            &-element {
              border-bottom: 1px solid var(--color-gray-950);
              height: 28px;
              position: relative;

              @include desktop {
                height: 32px;
              }

              &::after {
                display: none;
              }

              &:first-of-type {
                border-radius: 16px 16px 0 0;
              }

              &:last-of-type {
                border: none;
                border-radius: 0 0 16px 16px;
              }

              & > .volume {
                @include desktop {
                  display: none !important;
                }

                @include bigDesktop {
                  display: block !important;
                }
              }
            }
          }

          &.other-item {
            border: none;
            cursor: default;

            &:hover {
              background-color: transparent;
            }

            & > .icons {
              @include centered-row;
              max-width: 50px;
              overflow: hidden;

              & > .blockchain-icon {
                height: 16px;
                margin-right: -9px;
                width: 16px;
              }
            }
          }

          &.showPercentageMobile {
            & > .volume {
              display: none;
            }
          }

          &.showMoneyMobile {
            & > .percentage {
              display: none;
            }

            & > .chain {
              display: none;

              @include tablet {
                display: block;
              }
            }
          }

          &.left {
            &::after {
              background-color: var(--color-white-10);
              clip-path: inset(0 0 2px 0);
              content: "";
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              width: 2px;

              @include desktop {
                display: none;
              }
            }

            & > .volume {
              @include desktop {
                position: absolute;
                left: 102%;
              }

              @include bigDesktop {
                left: unset;
                position: relative;
              }
            }
          }

          &.right {
            justify-content: flex-end;

            &::after {
              background-color: var(--color-white-10);
              clip-path: inset(0 0 2px 0);
              content: "";
              height: 100%;
              position: absolute;
              right: 0;
              top: 0;
              width: 2px;

              @include desktop {
                display: none;
              }
            }

            & > .volume {
              @include desktop {
                position: absolute;
                right: 102%;
              }

              @include bigDesktop {
                position: relative;
                right: unset;
              }
            }
          }

          &.selected {
            cursor: default;

            &.left {
              &::after {
                background-color: var(--color-plum);

                @include desktop {
                  display: none;
                }
              }

              @include desktop {
                background: linear-gradient(270deg, var(--color-plum-12) 25%, transparent 100%);
              }
            }

            &.right {
              &::after {
                background-color: var(--color-plum);

                @include desktop {
                  display: none;
                }
              }

              @include desktop {
                background: linear-gradient(90deg, var(--color-plum-12) 25%, transparent 100%);
              }
            }

            & > .chain,
            & > .separator {
              opacity: 1;
            }

            & > .percentage {
              @include desktop {
                background-color: var(--color-lime);
                box-shadow: unset;
                color: var(--color-black);
              }
            }
          }
        }
      }

      &.left-selected,
      &-column-item-tooltip.right {
        & .right .chain,
        & .right .separator {
          opacity: 1;
        }

        & .right .percentage {
          @include desktop {
            background-color: var(--color-plum-12);
            box-shadow: unset;
            color: var(--color-lime);
          }
        }

        & .left:not(.selected) .blockchain-icon {
          filter: grayscale(100%);

          @include desktop {
            filter: unset;
          }
        }

        & .left:not(.selected) .volume {
          color: var(--color-gray-400);

          @include desktop {
            display: none;
          }

          @include bigDesktop {
            display: block;
          }
        }
      }

      &-column-item-tooltip.right .left:not(.selected) .volume {
        @include desktop {
          display: block;
        }
      }

      &.right-selected,
      &-column-item-tooltip.left {
        & .left .chain,
        & .left .separator {
          opacity: 1;
        }

        & .left .percentage {
          @include desktop {
            background-color: var(--color-plum-12);
            box-shadow: unset;
            color: var(--color-lime);
          }
        }

        & .right:not(.selected) .blockchain-icon {
          filter: grayscale(100%);

          @include desktop {
            filter: unset;
          }
        }

        & .right:not(.selected) .volume {
          color: var(--color-gray-400);

          @include desktop {
            display: none;
          }

          @include bigDesktop {
            display: block;
          }
        }
      }

      &-column-item-tooltip.left .right:not(.selected) .volume {
        @include desktop {
          display: block;
        }
      }

      &-svg {
        position: absolute;
        width: 100%;

        @include desktop {
          position: relative;
          width: 50%;
        }

        &-group-path {
          transform: scaleX(0.7);
          animation: scaleTo1 1s ease-out forwards;

          &.source {
            transform-origin: left center;
          }

          &.target {
            transform-origin: right center;
          }
        }
      }
    }
  }

  &-mobile {
    @include centered-row;
    gap: 6px;
    justify-content: flex-end;
    margin: 8px 0 0 auto;

    @include desktop {
      display: none;
    }

    &-btn {
      @include centered-row;
      background-color: transparent;
      border-radius: 12px;
      border: none;
      color: var(--color-white-60);
      cursor: pointer;
      padding: 6px;

      &.active {
        background-color: var(--color-gray-900);
        color: var(--color-white);
      }
    }
  }
}

@keyframes scaleTo1 {
  to {
    transform: scaleX(1);
  }
}
