@use "src/styles/globals.scss" as *;

.modal {
  &_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  &_content {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    border-radius: 12px 12px 0 0;
    box-shadow: 0 -5px 20px var(--color-gray-900);
    background-color: var(--color-gray-900);
    padding: 30px;

    @include desktop {
      box-shadow: 20px 20px 40px var(--color-gray-900);
      border-radius: 12px;
      max-width: 720px;
      width: 100%;
      text-align: center;
      position: relative;
      padding: 0;
    }
  }

  &_close {
    @include centered-row;
    justify-content: center;

    position: absolute;
    top: 10px;
    right: 10px;
    border: 0px;
    border-radius: 50%;

    width: 24px;
    height: 24px;

    color: var(--color-white);
    background-color: var(--color-white-10);
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background-color: var(--color-white-20);
    }
  }
}
