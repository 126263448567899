@use "src/styles/globals.scss" as *;

.tooltip {
  &-container {
    @include text-roboto-body-400;
    background-color: var(--color-gray-900);
    border-radius: 16px;
    box-shadow: 0px 4px 8px var(--color-black-30);
    min-height: 30px;
    padding: 8px;
    word-wrap: break-word;
    z-index: 8;

    &.onlyContent {
      background-color: var(--color-black);
      box-shadow: none;
      padding: 0px;
    }

    &.info {
      margin: 0 16px;
      max-width: calc(100vw - 32px);
      max-width: calc(100svw - 32px);
      padding: 12px 16px;
    }

    &.right,
    &.left {
      margin: 0;
    }

    &.max-width {
      max-width: 287px;
    }
  }

  &-arrow {
    fill: var(--color-gray-900);
  }
}

[data-radix-popper-content-wrapper] {
  z-index: 1000 !important;
}
