:root {
  --color-gray-10: #e6e6e61a;
  --color-gray-20: #ccc3;
  --color-gray-100: #e6e6e6;
  --color-gray-200: #ccc;
  --color-gray-300: #b3b3b3;
  --color-gray-400: #999;
  --color-gray-500: gray;
  --color-gray-600: #666;
  --color-gray-700: #4d4d4d;
  --color-gray-800: #333;
  --color-gray-900: #1f1f1f;
  --color-gray-950: #121212;
  --color-gray-975: #0c0c0c;
  --color-coral-100: #fcf2e6;
  --color-coral-200: #f9e3cb;
  --color-coral-300: #f1cdad;
  --color-coral-400: #e4b393;
  --color-coral-500: #d29070;
  --color-coral-600: #b56b52;
  --color-coral-700: #974a38;
  --color-coral-800: #7a2d23;
  --color-coral-900: #651a15;
  --color-coral: #fd8058;
  --color-coral-12: #fd80581f;
  --color-coral-20: #fd805833;
  --color-coral-40: #fd805866;
  --color-plum-100: #f4f2fd;
  --color-plum-200: #eae8fd;
  --color-plum-300: #dddaf7;
  --color-plum-400: #cfcdf2;
  --color-plum-500: #bebbea;
  --color-plum-600: #8d88ca;
  --color-plum-700: #605ea7;
  --color-plum-800: #3e3c87;
  --color-plum-900: #262370;
  --color-plum: #c1bbf6;
  --color-plum-12: #c1bbf61f;
  --color-plum-20: #c1bbf633;
  --color-plum-40: #c1bbf666;
  --color-plum-50: #c1bbf680;
  --color-plum-60: #c1bbf699;
  --color-plum-70: #c1bbf6b3;
  --color-lime-100: #fefde9;
  --color-lime-200: #f8fbd5;
  --color-lime-300: #f4f7be;
  --color-lime-400: #eef1ac;
  --color-lime-500: #e3e98f;
  --color-lime-600: #c1c868;
  --color-lime-700: #a0a748;
  --color-lime-800: #7e872c;
  --color-lime-900: #676f1a;
  --color-lime: #dde95a;
  --color-lime-12: #dde95a1f;
  --color-lime-20: #dde95a33;
  --color-lime-40: #dde95a66;
  --color-black: #000;
  --color-black-90: #000000e6;
  --color-black-80: #000c;
  --color-black-70: #000000b3;
  --color-black-60: #0009;
  --color-black-50: #00000080;
  --color-black-40: #0006;
  --color-black-30: #0000004d;
  --color-black-25: #00000040;
  --color-black-20: #0003;
  --color-black-10: #0000001a;
  --color-black-05: #0000000d;
  --color-white: #fff;
  --color-white-90: #ffffffe6;
  --color-white-80: #fffc;
  --color-white-70: #ffffffb2;
  --color-white-60: #fff9;
  --color-white-50: #ffffff7f;
  --color-white-40: #fff6;
  --color-white-30: #ffffff4d;
  --color-white-20: #ffffff4c;
  --color-white-10: #ffffff19;
  --color-white-05: #ffffff0c;
  --color-white-03: #ffffff07;
  --color-success-100: #7bff3d;
  --color-success-60: #7bff3d99;
  --color-success-30: #7bff3d4d;
  --color-success-20: #7bff3d33;
  --color-success-15: #7bff3d26;
  --color-success-10: #7bff3d1a;
  --color-alert-100: #ffd74a;
  --color-alert-60: #ffd74a99;
  --color-alert-30: #ffd74a4d;
  --color-alert-20: #ffd74a33;
  --color-alert-10: #ffd74a1a;
  --color-information-100: #7efff0;
  --color-information-60: #7efff099;
  --color-information-30: #7efff04d;
  --color-information-15: #7efff026;
  --color-information-12: #7efff01f;
  --color-error-100: #ff4d63;
  --color-error-60: #ff4d6399;
  --color-error-30: #ff4d634d;
  --color-error-15: #ff4d6326;
}

*, :before, :after {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: var(--color-white-20) var(--color-black-10);
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}

html {
  background-color: var(--color-black);
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  background-color: var(--color-black);
  color: var(--color-white);
  min-width: 360px;
  margin: 0;
}

#app {
  flex-direction: column;
  min-height: 100svh;
  padding: 0 16px;
  display: flex;
  position: relative;
  overflow: clip;
}

@media only screen and (width >= 768px) {
  #app {
    padding: 0 60px;
  }
}

@media only screen and (width >= 1024px) {
  #app {
    padding: 0 120px;
  }
}

#app > .main-content {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.apexcharts-tooltip {
  box-shadow: none !important;
  background-color: #0000 !important;
  border: none !important;
  border-radius: 0 !important;
}

.apexcharts-xcrosshairs {
  fill: var(--color-white-05);
}

.apexcharts-active {
  stroke: var(--color-gray-600);
  stroke-linecap: unset;
  fill-opacity: 1;
  stroke-dasharray: none;
}

.apexcharts-xaxis-label, .apexcharts-yaxis-label {
  text-transform: uppercase;
}

.apexcharts-svg {
  overflow: visible;
}

.metamask-btn > button {
  letter-spacing: .02em;
  background: var(--color-plum);
  color: var(--color-white);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background: var(--color-gray-900);
  border: none;
  border-radius: 27px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  height: 36px;
  padding: 0 12px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.metamask-btn > button:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.metamask-btn > button > svg {
  transition: transform .2s;
}

.metamask-btn > button:not(:disabled):hover {
  background: var(--color-gray-800);
}

.metamask-btn > button:not(:disabled):active {
  background: var(--color-gray-900);
}

.metamask-btn-tooltip {
  letter-spacing: .02em;
  color: var(--color-white);
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.metamask-btn-tooltip > span {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
}

.metamask-btn-tooltip > span > .icon {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.metamask-btn-tooltip > span > .icon.copy {
  cursor: pointer;
}

.banner-message {
  text-wrap: pretty;
  gap: 12px;
  height: 102px;
  display: flex;
}

.banner-message-bg {
  background: linear-gradient(180deg, var(--color-coral-40) 0%, var(--color-black-20) 100%);
  height: inherit;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.banner-message-content {
  z-index: 1;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin: 16px 0 auto;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .banner-message-content {
    margin-top: 16px;
  }
}

.banner-message-content-icon {
  background-color: var(--color-coral-20);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  min-width: 34px;
  height: 34px;
  margin-bottom: auto;
  display: flex;
}

.banner-message-content-icon-dot {
  background-color: var(--color-coral);
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.banner-message-content-text {
  letter-spacing: .03em;
  color: var(--color-white);
  text-transform: uppercase;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.banner-message-content-text a.navlink {
  color: inherit;
  outline: 0;
  text-decoration: none;
}

.banner-message-content-text-link {
  color: var(--color-coral);
  margin-left: 8px;
  margin-right: 8px;
  text-decoration: underline;
}

.blockchain-icon {
  border-radius: 50%;
  display: block;
}

.custom-checkbox {
  border: 2px solid var(--color-gray-800);
  color: var(--color-black);
  border-radius: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.custom-checkbox.checked {
  background-color: var(--color-plum);
  border: none;
}

.chip {
  letter-spacing: .02em;
  background-color: var(--color-white);
  color: var(--color-black);
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.chip.green {
  background-color: var(--color-success-15);
  color: var(--color-success-100);
}

.chip.orange {
  background-color: var(--color-alert-30);
  color: var(--color-alert-100);
}

.chip.red {
  background-color: var(--color-error-30);
  color: var(--color-error-100);
}

.community-banner {
  background: linear-gradient(#7abfff1f 37%, #7abfff00 85%);
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px 16px;
  margin-bottom: 48px;
  padding: 32px 16px 0;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .community-banner {
    border-bottom: 1px solid var(--color-gray-900);
    background: linear-gradient(270deg, #7abfff1f 25%, #7abfff00 100%);
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 80px;
    padding: 0 16px;
    display: flex;
  }
}

.community-banner > svg {
  min-width: 150px;
}

.community-banner-text {
  flex-direction: column;
  justify-content: center;
  max-width: 552px;
  padding: 16px 0;
  display: flex;
}

.community-banner-text-title {
  letter-spacing: .02em;
  color: var(--color-plum);
  text-align: center;
  margin-bottom: 24px;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
}

@media only screen and (width >= 1024px) {
  .community-banner-text-title {
    text-align: start;
    font-size: 30px;
  }
}

.community-banner-text-description {
  letter-spacing: .02em;
  color: var(--color-gray-100);
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@media only screen and (width >= 1024px) {
  .community-banner-text-description {
    text-align: start;
  }
}

.community-banner-btn {
  letter-spacing: .02em;
  background: var(--color-plum);
  color: var(--color-black);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background-color: var(--color-coral);
  border: none;
  border-radius: 27px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  height: 36px;
  padding: 0 12px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.community-banner-btn:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.community-banner-btn > svg {
  transition: transform .2s;
}

.community-banner-btn:not(:disabled):hover {
  background: linear-gradient(0deg, #ffffff1a, #ffffff1a), var(--color-coral);
}

.community-banner-btn:not(:disabled):active {
  background: linear-gradient(0deg, #0003, #0003), var(--color-coral);
}

.community-banner-btn > svg {
  opacity: .6;
}

.counter {
  letter-spacing: .02em;
  background-color: var(--color-white);
  color: var(--color-gray-900);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.counter.inverted {
  background-color: var(--color-white);
  font-weight: 500;
}

@media only screen and (width >= 1024px) {
  .counter.mobile {
    display: none;
  }
}

.governor-header {
  background-color: var(--color-gray-950);
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
  padding: 20px 20px 24px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .governor-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 28px 32px;
    display: flex;
  }
}

.governor-header-title {
  letter-spacing: .02em;
  color: var(--color-white);
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.governor-header-description {
  letter-spacing: .02em;
  color: var(--color-gray-400);
  text-wrap: pretty;
  max-width: 576px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.governor-header-description-link {
  letter-spacing: .02em;
  color: var(--color-white);
  cursor: pointer;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
  text-underline-offset: 3px;
  background: none;
  border: none;
  flex-direction: row;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: inline;
}

.governor-header-description-link:not(:disabled):hover {
  -webkit-text-decoration-color: var(--color-gray-400);
  text-decoration-color: var(--color-gray-400);
}

.governor-header-description-link:not(:disabled):active {
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
}

.governor-header-description-link:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.json-view-row {
  margin-left: 16px;
  position: relative;
}

@media only screen and (width >= 768px) {
  .json-view-row {
    word-break: break-all;
  }
}

.json-view-expandIcon {
  cursor: pointer;
  padding: 0 6.1px;
  position: absolute;
  left: -18.2px;
}

.json-view-expandIcon:before {
  border-color: transparent transparent transparent var(--color-white);
  content: "";
  border-style: solid;
  border-width: 4px 0 4px 6px;
  display: inline-block;
}

.json-view-expandIcon:hover {
  opacity: .5;
}

.json-view-collapseIcon {
  cursor: pointer;
  padding: 0 5.1px;
  position: absolute;
  left: -18.2px;
}

.json-view-collapseIcon:before {
  border-color: var(--color-white) transparent transparent transparent;
  content: "";
  cursor: pointer;
  border-style: solid;
  border-width: 6px 4px 0;
  display: inline-block;
}

.json-view-collapseIcon:hover {
  opacity: .5;
}

.json-view-collapsedContent {
  cursor: pointer;
}

.json-view-collapsedContent:after {
  content: " ... ";
}

.json-view-collapsedContent:hover {
  filter: brightness(1.4);
}

.json-view-key {
  color: var(--color-gray-500);
  padding-right: 4px;
}

.json-view-info {
  color: var(--color-coral);
}

.json-view-number {
  color: var(--color-lime);
}

.json-view-string {
  color: var(--color-white);
}

.loader {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 32px 16px;
  display: flex;
}

.modal_overlay {
  z-index: 1000;
  background-color: #000000b3;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.modal_content {
  max-width: 100%;
  box-shadow: 0 -5px 20px var(--color-gray-900);
  background-color: var(--color-gray-900);
  border-radius: 12px 12px 0 0;
  padding: 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media only screen and (width >= 1024px) {
  .modal_content {
    box-shadow: 20px 20px 40px var(--color-gray-900);
    text-align: center;
    border-radius: 12px;
    width: 100%;
    max-width: 720px;
    padding: 0;
    position: relative;
  }
}

.modal_close {
  width: 24px;
  height: 24px;
  color: var(--color-white);
  background-color: var(--color-white-10);
  cursor: pointer;
  border: 0;
  border-radius: 50%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal_close:hover {
  background-color: var(--color-white-20);
}

.pagination {
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 100%;
  min-height: 36px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .pagination {
    width: max-content;
  }
}

.pagination-pages {
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin: 0 auto;
  display: flex;
}

.pagination > button, .pagination > span, .pagination > .pagination-pages > button, .pagination > .pagination-pages > span {
  letter-spacing: .02em;
  border: 1px solid var(--color-gray-900);
  color: var(--color-gray-400);
  cursor: pointer;
  text-align: center;
  background-color: #0000;
  border-radius: 50%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  min-height: 36px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
}

.pagination > button:disabled, .pagination > span:disabled, .pagination > .pagination-pages > button:disabled, .pagination > .pagination-pages > span:disabled {
  cursor: not-allowed;
}

.pagination > button:not(.current):disabled, .pagination > span:not(.current):disabled, .pagination > .pagination-pages > button:not(.current):disabled, .pagination > .pagination-pages > span:not(.current):disabled {
  opacity: .5;
}

.pagination > button:not(:disabled):hover, .pagination > span:not(:disabled):hover, .pagination > .pagination-pages > button:not(:disabled):hover, .pagination > .pagination-pages > span:not(:disabled):hover {
  background-color: var(--color-white-05);
}

.pagination > button:not(:disabled):active, .pagination > span:not(:disabled):active, .pagination > .pagination-pages > button:not(:disabled):active, .pagination > .pagination-pages > span:not(:disabled):active {
  background-color: var(--color-gray-900);
}

.pagination > button.page, .pagination > span.page, .pagination > .pagination-pages > button.page, .pagination > .pagination-pages > span.page {
  color: var(--color-gray-400);
  border: 1px solid #0000;
}

.pagination > button.current, .pagination > span.current, .pagination > .pagination-pages > button.current, .pagination > .pagination-pages > span.current {
  background-color: var(--color-gray-900);
  color: var(--color-white);
  border: 1px solid #0000;
}

.select {
  white-space: nowrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 36px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .select {
    width: max-content;
  }
}

.select__control {
  letter-spacing: .02em;
  background: var(--color-plum);
  color: var(--color-white);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background: var(--color-gray-900);
  border: none;
  border-radius: 27px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 36px;
  padding: 0 8px 0 12px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.select__control:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.select__control > svg {
  transition: transform .2s;
}

.select__control:not(:disabled):hover {
  background: var(--color-gray-800);
}

.select__control:not(:disabled):active {
  background: var(--color-gray-900);
}

@media only screen and (width >= 1024px) {
  .select__control {
    width: max-content;
  }
}

.select__control > .select__indicators {
  transition: transform .2s;
}

.select__control--menu-is-open {
  background-color: var(--color-gray-800);
}

.select__control--menu-is-open > .select__indicators {
  transform: rotate(-180deg);
}

.select__control > .select__value-container {
  max-width: max-content;
}

.select__placeholder {
  color: var(--color-white-50);
}

.select__indicators {
  color: var(--color-white);
  flex-direction: row;
  align-items: center;
  display: flex;
}

.select__menu {
  min-width: max-content;
  margin-top: 8px;
  right: 0;
}

.select__menu-notice--no-options {
  letter-spacing: .02em;
  color: var(--color-white-50);
  padding: 8px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.select__menu-list {
  letter-spacing: .02em;
  background-color: var(--color-gray-900);
  min-width: max-content;
  overflow: overlay;
  white-space: nowrap;
  border-radius: 12px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.select__menu-list-scroll-button {
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 20px;
  display: flex;
}

.select__menu-list > .search-container {
  margin-top: 16px;
}

.select__menu-list > .search-container > input {
  background-color: var(--color-gray-800);
  border: 1px solid var(--color-gray-800);
  color: var(--color-white-50);
  z-index: 50;
  border-radius: 24px;
  width: 100%;
  padding: 8px 16px;
}

.select__menu-list > .search-container > input:focus {
  outline: #0000;
}

.select__menu-list::-webkit-scrollbar {
  width: 12px;
  height: 4px;
}

.select__menu-list::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: var(--color-white-20);
  border: 4px solid #0000;
  border-radius: 8px;
}

.select__option {
  letter-spacing: .02em;
  height: inherit;
  width: inherit;
  color: var(--color-white);
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #0000;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  cursor: pointer !important;
}

.select__option:hover:not(.select__option--is-selected):not(.select__option--is-disabled) {
  background-color: var(--color-white-05);
}

.select__option:focus-visible {
  background-color: var(--color-white-05);
  outline: #0000;
}

.select__option--is-selected {
  background-color: var(--color-gray-800);
  color: var(--color-white);
}

.select__option--is-disabled {
  color: var(--color-white-50);
  cursor: not-allowed !important;
}

.select.secondary .select__control {
  letter-spacing: .03em;
  height: auto;
  min-height: unset;
  text-transform: uppercase;
  background-color: #0000;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.select-custom-option {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  display: flex;
}

.select-custom-option-container {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
}

.select-custom-option-text {
  flex-direction: column;
  justify-content: center;
  gap: 0;
  display: flex;
}

.select-custom-option-text-subtitle {
  color: var(--color-white-50);
  margin-left: 2px;
  font-size: 12px;
}

.select-custom-option-text-subtitle p {
  margin: 2px 0 0;
  padding: 0;
  line-height: 1.3;
}

.select-searchable {
  position: relative;
}

.select-searchable-dropdown {
  letter-spacing: .02em;
  background: var(--color-plum);
  color: var(--color-white);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background: var(--color-gray-900);
  border: none;
  border-radius: 27px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  height: 36px;
  padding: 0 8px 0 12px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.select-searchable-dropdown:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.select-searchable-dropdown > svg {
  transition: transform .2s;
}

.select-searchable-dropdown:not(:disabled):hover {
  background: var(--color-gray-800);
}

.select-searchable-dropdown:not(:disabled):active {
  background: var(--color-gray-900);
}

.select-searchable-dropdown.disabled {
  cursor: not-allowed;
}

.select-searchable-dropdown.open > svg {
  transform: rotate(-180deg);
}

.select-searchable-menu {
  background-color: var(--color-gray-900);
  z-index: 50;
  border-radius: 24px 24px 0 0;
  width: 100%;
  min-width: max-content;
  margin-top: 16px;
  padding: 16px 16px 24px;
  position: absolute;
}

@media only screen and (width >= 1024px) {
  .select-searchable-menu {
    width: max-content;
  }
}

.select-searchable-menu .select__value-container {
  max-width: none;
}

.select-searchable-menu .select__control {
  font-size: 16px;
}

@media only screen and (width >= 1024px) {
  .select-searchable-menu .select__control {
    letter-spacing: .02em;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.select-searchable-menu .select__control:after {
  content: "";
  background-image: url("selectSearchIcon.db543e37.svg");
  background-size: cover;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 8px;
}

.select-searchable-menu .select__control > .select__value-container {
  padding-left: 20px;
}

.select-searchable-menu .select__control > svg {
  color: var(--color-gray-400);
}

.switch {
  flex-direction: row;
  align-items: center;
  gap: 19px;
  display: flex;
}

.switch-label {
  letter-spacing: .03em;
  color: var(--color-white);
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
}

.switch-label-indicator {
  background-color: var(--color-gray-20);
  border-radius: 50%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.switch-label-indicator-dot {
  background-color: var(--color-gray-200);
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.switch-label-indicator.active {
  background-color: var(--color-plum-20);
}

.switch-label-indicator.active .switch-label-indicator-dot {
  background-color: var(--color-plum);
  animation: 1.5s infinite pulse;
}

.switch-root {
  all: unset;
  background-color: var(--color-gray-800);
  cursor: pointer;
  border-radius: 9999px;
  width: 44px;
  height: 24px;
  transition: background-color .2s;
  position: relative;
}

.switch-root[data-state="checked"] {
  background-color: var(--color-plum-600);
}

.switch-root-thumb {
  background-color: var(--color-gray-100);
  border-radius: 9999px;
  width: 18px;
  height: 18px;
  transition: transform .2s;
  display: block;
  transform: translateX(3px);
}

.switch-root-thumb[data-state="checked"] {
  transform: translateX(23px);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.tag {
  background-color: var(--color-white-10);
  color: var(--color-white);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 6px 14px;
  display: flex;
}

.tag.blue {
  background-color: var(--color-information-30);
  color: var(--color-information-500);
}

.tag.chip {
  border-radius: 16px;
  padding: 4px 12px;
}

.tag.small {
  font-size: 12px;
}

.toggle-group {
  flex-direction: row;
  align-items: center;
  gap: 12px;
  max-width: max-content;
  display: flex;
  overflow-x: auto;
}

.toggle-group-root {
  background-color: var(--color-gray-900);
  border-radius: 24px;
  flex-direction: row;
  align-items: center;
  gap: 12px 6px;
  display: flex;
}

.toggle-group-item {
  letter-spacing: .02em;
  background: var(--color-plum);
  color: var(--color-gray-400);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background: var(--color-gray-900);
  background-color: #0000;
  border: none;
  border-radius: 27px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  height: 36px;
  padding: 0 12px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.toggle-group-item:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.toggle-group-item > svg {
  transition: transform .2s;
}

.toggle-group-item:not(:disabled):hover {
  background: var(--color-gray-800);
}

.toggle-group-item:not(:disabled):active {
  background: var(--color-gray-900);
}

.toggle-group-item[data-state="on"] {
  letter-spacing: .02em;
  background: var(--color-plum);
  color: var(--color-white);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background: var(--color-gray-900);
  background-color: var(--color-gray-800);
  border: none;
  border-radius: 27px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  height: 36px;
  padding: 0 12px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.toggle-group-item[data-state="on"]:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.toggle-group-item[data-state="on"] > svg {
  transition: transform .2s;
}

.toggle-group-item[data-state="on"]:not(:disabled):hover {
  background: var(--color-gray-800);
}

.toggle-group-item[data-state="on"]:not(:disabled):active {
  background: var(--color-gray-900);
}

.toggle-group.secondary .toggle-group-root {
  border-radius: 12px;
}

.toggle-group.secondary .toggle-group-root .toggle-group-item {
  border-radius: 12px;
  padding: 6px;
}

.tooltip-container {
  letter-spacing: .02em;
  background-color: var(--color-gray-900);
  box-shadow: 0px 4px 8px var(--color-black-30);
  word-wrap: break-word;
  z-index: 8;
  border-radius: 16px;
  min-height: 30px;
  padding: 8px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.tooltip-container.onlyContent {
  background-color: var(--color-black);
  box-shadow: none;
  padding: 0;
}

.tooltip-container.info {
  max-width: calc(100svw - 32px);
  margin: 0 16px;
  padding: 12px 16px;
}

.tooltip-container.right, .tooltip-container.left {
  margin: 0;
}

.tooltip-container.max-width {
  max-width: 287px;
}

.tooltip-arrow {
  fill: var(--color-gray-900);
}

[data-radix-popper-content-wrapper] {
  z-index: 1000 !important;
}

.banner {
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  width: 100%;
  margin-bottom: 32px;
  padding: 24px 16px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .banner {
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 0;
    padding: 32px;
    display: flex;
  }
}

@media only screen and (width >= 1440px) {
  .banner {
    margin-top: 32px;
  }
}

.banner-warning {
  color: #fff2e9;
  background-color: #995a341a;
}

.banner-warning .banner-content-description {
  max-width: 936px;
}

.banner-error {
  color: #ffe3e9;
  background-color: #f520201a;
}

.banner-error .banner-content-description {
  max-width: 856px;
}

.banner-success {
  color: #e1fffd;
  background-color: #01bbac1a;
}

.banner-success .banner-content-description {
  max-width: 816px;
}

.banner-icon {
  margin-bottom: auto;
}

.banner-content {
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  display: flex;
}

.banner-content-title-text {
  display: block;
}

@media only screen and (width >= 768px) {
  .banner-content-title-text {
    display: inline-block;
  }
}

.banner-content-title-hyphen {
  display: none;
}

@media only screen and (width >= 768px) {
  .banner-content-title-hyphen {
    display: inline-block;
  }
}

.banner-content-title-span {
  color: #01bbac;
  display: block;
}

@media only screen and (width >= 768px) {
  .banner-content-title-span {
    display: inline-block;
  }
}

.banner-content-description {
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  font-size: 14px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .banner-content-description {
    gap: 0;
    font-size: 16px;
  }
}

.banner-content-description-span {
  font-size: 14px;
}

@media only screen and (width >= 1024px) {
  .banner-content-description-span {
    font-size: 16px;
  }
}

.banner-content-description-span-break {
  margin-top: 24px;
  display: inline-block;
}

@media only screen and (width >= 1024px) {
  .banner-content-description-span-break {
    display: inline;
  }
}

.banner-content-description-span-warning {
  color: var(--color-alert-100);
  font-weight: 600;
}

.block-section-btn {
  letter-spacing: .02em;
  color: var(--color-white);
  cursor: pointer;
  background-color: #0000;
  border: none;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
}

.block-section-btn:hover {
  color: var(--color-white-70);
}

.block-section-btn.show > svg {
  rotate: 180deg;
}

.block-section-block {
  background-color: var(--color-gray-950);
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  gap: 18px;
  margin-bottom: 32px;
  padding: 16px;
  display: flex;
}

.block-section-block-top {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.block-section-block-title, .block-section-block-copy {
  letter-spacing: .02em;
  color: var(--color-gray-500);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.block-section-block-copy > .copy-to-clipboard {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
}

.block-section-block-body {
  letter-spacing: .02em;
  background-color: var(--color-black);
  color: var(--color-white);
  border-radius: 12px;
  padding: 16px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  overflow: auto;
}

.block-section-block-body.hide {
  display: none;
}

.calendar-custom {
  position: relative;
}

.calendar-custom-text {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
}

.calendar-custom-btn {
  letter-spacing: .02em;
  background: var(--color-plum);
  color: var(--color-white);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background: var(--color-gray-900);
  border: none;
  border-radius: 27px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 48px;
  padding: 0 8px 0 12px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.calendar-custom-btn:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.calendar-custom-btn > svg {
  transition: transform .2s;
}

.calendar-custom-btn:not(:disabled):hover {
  background: var(--color-gray-800);
}

.calendar-custom-btn:not(:disabled):active {
  background: var(--color-gray-900);
}

@media only screen and (width >= 1024px) {
  .calendar-custom-btn {
    height: 36px;
    position: relative;
  }
}

.calendar-custom-btn-text {
  align-items: center;
  gap: 4px;
  display: flex;
}

.calendar-custom-btn-text > svg {
  color: var(--color-white-60);
}

.calendar-custom-btn-text .range-text {
  display: block;
}

@media only screen and (width >= 1024px) {
  .calendar-custom-btn-text .range-text {
    display: none;
  }
}

@media only screen and (width >= 1260px) {
  .calendar-custom-btn-text .range-text {
    display: block;
  }
}

.calendar-custom-box {
  background-color: var(--color-gray-900);
  z-index: 55;
  border-radius: 16px;
  flex-direction: column;
  width: calc(100% - 42px);
  min-width: max-content;
  margin-top: 12px;
  transition: inset .3s;
  display: none;
  position: fixed;
}

@media only screen and (width >= 1024px) {
  .calendar-custom-box {
    inset: unset;
    border: none;
    border-radius: 8px;
    width: auto;
    margin-top: 0;
    transition: none;
    display: none;
    position: absolute;
    left: 0;
  }
}

.calendar-custom-box.show-date {
  flex-direction: column-reverse;
  max-height: calc(100svh - 200px);
  display: flex;
  overflow: auto;
}

@media only screen and (width >= 1024px) {
  .calendar-custom-box.show-date {
    inset: unset;
    border-radius: 16px;
    flex-direction: row-reverse;
    display: flex;
    top: 52px;
    left: 0;
    transform: translateX(-42%);
  }
}

@media only screen and (width >= 1440px) {
  .calendar-custom-box.show-date {
    transform: unset;
  }
}

.calendar-custom-box-chains {
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px 24px;
  padding: 16px;
  display: flex;
  overflow-y: auto;
}

@media only screen and (width >= 1024px) {
  .calendar-custom-box-chains {
    grid-template-columns: repeat(4, 1fr);
    display: grid;
  }
}

.calendar-custom-box-chains-item {
  cursor: pointer;
  opacity: .5;
  white-space: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .calendar-custom-box-chains-item {
    flex-direction: row-reverse;
    justify-content: start;
  }
}

.calendar-custom-box-chains-item:hover {
  opacity: 1;
}

.calendar-custom-box-chains-item.all-chains {
  grid-column: span 4;
  margin-bottom: 16px;
}

.calendar-custom-box-chains-item-checkbox {
  color: var(--color-white);
  cursor: pointer;
  border: 1px solid #6e71a3;
  border-radius: 2px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  display: flex;
}

.calendar-custom-box-chains-bottom {
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  padding: 24px 16px 16px;
  display: grid;
}

@media only screen and (width >= 1024px) {
  .calendar-custom-box-chains-bottom {
    flex-direction: row-reverse;
    display: flex;
  }
}

.calendar-custom-box-chains-bottom > button {
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 44px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.calendar-custom-box-chains-bottom > button:disabled {
  cursor: not-allowed;
  opacity: .3;
}

.calendar-custom-box-date-selector {
  border-bottom: 1px solid var(--color-gray-800);
  flex-direction: column;
  justify-content: flex-end;
  gap: 73px;
  width: 100%;
  padding: 8px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .calendar-custom-box-date-selector {
    border-bottom: none;
    border-right: 1px solid var(--color-gray-800);
    justify-content: flex-start;
    width: 156px;
  }
}

.calendar-custom-box-date-selector > div:first-child {
  grid-template-columns: 1fr 1fr;
  gap: 0 16px;
  display: grid;
}

@media only screen and (width >= 1024px) {
  .calendar-custom-box-date-selector > div:first-child {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }
}

.calendar-custom-box-date-selector > div {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px 0;
  display: flex;
}

.calendar-custom-box-date-selector > div > .btn {
  letter-spacing: .02em;
  color: var(--color-gray-400);
  text-align: start;
  background-color: #0000;
  border: none;
  border-radius: 8px;
  width: 100%;
  padding: 8px 0 8px 8px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.calendar-custom-box-date-selector > div > .btn:not(:disabled) {
  cursor: pointer;
}

.calendar-custom-box-date-selector > div > .btn:not(:disabled):hover {
  background-color: var(--color-white-05);
}

.calendar-custom-box-date-selector > div > .btn.active {
  background-color: var(--color-gray-800);
  color: var(--color-white);
}

.calendar-custom-box-date-calendar {
  width: 100%;
  margin: 0 auto auto;
  padding: 16px 16px 72px;
}

@media only screen and (width >= 1024px) {
  .calendar-custom-box-date-calendar {
    min-height: unset;
    width: max-content;
    margin: 0;
  }
}

.calendar-custom-box-date-calendar .react-datepicker {
  background-color: #0000;
  border: none;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  display: flex;
}

@media only screen and (width >= 768px) {
  .calendar-custom-box-date-calendar .react-datepicker {
    flex-direction: row;
    align-items: flex-start;
    display: flex;
  }
}

@media only screen and (width >= 1024px) {
  .calendar-custom-box-date-calendar .react-datepicker {
    display: block;
  }
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__navigation {
  border-radius: 50%;
  width: 36px;
  top: 4px;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__navigation > span:before {
  border-color: var(--color-white);
  top: 0;
}

@media only screen and (width >= 1024px) {
  .calendar-custom-box-date-calendar .react-datepicker .react-datepicker__navigation > span:before {
    top: 4px;
  }
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month-container:first-of-type .react-datepicker__current-month {
  visibility: hidden;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__header {
  background-color: #0000;
  border: none;
  border-radius: 0;
  padding: 0;
}

@media only screen and (width >= 1024px) {
  .calendar-custom-box-date-calendar .react-datepicker .react-datepicker__header {
    padding: 8px 0 0;
  }
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__header .react-datepicker__current-month {
  letter-spacing: .02em;
  color: var(--color-white);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__header .react-datepicker__day-names {
  padding: 16px 0 0;
}

@media only screen and (width >= 1024px) {
  .calendar-custom-box-date-calendar .react-datepicker .react-datepicker__header .react-datepicker__day-names {
    padding: 16px 0 8px;
  }
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
  letter-spacing: .03em;
  color: var(--color-gray-600);
  text-transform: uppercase;
  width: 36px;
  height: 36px;
  margin: 0;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 36px;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month {
  margin: 0 24px;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month .react-datepicker__day {
  letter-spacing: .02em;
  color: var(--color-white);
  border-radius: 0;
  width: 36px;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 36px;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month .react-datepicker__day:hover {
  background-color: var(--color-gray-600);
  color: var(--color-white-50);
  font-weight: 600;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month .react-datepicker__day--keyboard-selected {
  background-color: #0000;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month .react-datepicker__day--in-selecting-range {
  background-color: var(--color-white-05);
  color: var(--color-white-50);
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month .react-datepicker__day--selected {
  background-color: var(--color-gray-800);
  color: var(--color-white-50);
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month .react-datepicker__day--in-range {
  background-color: var(--color-white-05);
  color: var(--color-white-50);
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month .react-datepicker__day--range-start:not(.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month .react-datepicker__day--outside-month) {
  background-color: var(--color-white-05);
  color: var(--color-white-90);
  z-index: 1;
  border-radius: 50% 0 0 50%;
  font-weight: 600;
  position: relative;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month .react-datepicker__day--range-start:not(.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month .react-datepicker__day--outside-month):before {
  background-color: var(--color-gray-800);
  content: "";
  z-index: -1;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month .react-datepicker__day--range-end:not(.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month .react-datepicker__day--outside-month) {
  background-color: var(--color-white-05);
  color: var(--color-white-90);
  z-index: 1;
  border-radius: 0 50% 50% 0;
  font-weight: 600;
  position: relative;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month .react-datepicker__day--range-end:not(.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month .react-datepicker__day--outside-month):before {
  background-color: var(--color-gray-800);
  content: "";
  z-index: -1;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month .react-datepicker__day--disabled {
  color: var(--color-white);
  opacity: .3;
  text-decoration: line-through;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month .react-datepicker__day--disabled:hover {
  color: var(--color-white);
  cursor: not-allowed;
  background-color: #0000;
  font-weight: 400;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__header__dropdown {
  position: absolute;
  top: 6.5px;
  left: 0;
  right: 0;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container, .calendar-custom-box-date-calendar .react-datepicker .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container {
  margin: 0;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month-read-view--selected-month, .calendar-custom-box-date-calendar .react-datepicker .react-datepicker__year-read-view--selected-year {
  letter-spacing: .02em;
  color: var(--color-white);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month-read-view, .calendar-custom-box-date-calendar .react-datepicker .react-datepicker__year-read-view {
  border: none;
  margin: 0 2px;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, .calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, .calendar-custom-box-date-calendar .react-datepicker .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, .calendar-custom-box-date-calendar .react-datepicker .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow {
  border-color: var(--color-gray-600);
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month-read-view--down-arrow, .calendar-custom-box-date-calendar .react-datepicker .react-datepicker__year-read-view--down-arrow {
  border-color: var(--color-gray-600);
  margin: 0 auto;
  top: 20px;
  left: 0;
  right: 0;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month-dropdown, .calendar-custom-box-date-calendar .react-datepicker .react-datepicker__year-dropdown {
  background-color: var(--color-gray-800);
  z-index: 88;
  border: none;
  border-radius: 16px;
  margin-top: -26px;
  padding: 8px;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month-dropdown .react-datepicker__month-option, .calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month-dropdown .react-datepicker__year-option, .calendar-custom-box-date-calendar .react-datepicker .react-datepicker__year-dropdown .react-datepicker__month-option, .calendar-custom-box-date-calendar .react-datepicker .react-datepicker__year-dropdown .react-datepicker__year-option {
  letter-spacing: .02em;
  color: var(--color-white);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month-dropdown .react-datepicker__month-option:hover, .calendar-custom-box-date-calendar .react-datepicker .react-datepicker__month-dropdown .react-datepicker__year-option:hover, .calendar-custom-box-date-calendar .react-datepicker .react-datepicker__year-dropdown .react-datepicker__month-option:hover, .calendar-custom-box-date-calendar .react-datepicker .react-datepicker__year-dropdown .react-datepicker__year-option:hover {
  background-color: var(--color-white-05);
}

.calendar-custom-box-date-calendar-one-day-selected .react-datepicker .react-datepicker__month .react-datepicker__day--range-start, .calendar-custom-box-date-calendar-one-day-selected .react-datepicker .react-datepicker__month .react-datepicker__day--range-end {
  border-radius: 0;
}

.calendar-custom-box-date-calendar-btns {
  flex-direction: row;
  align-items: center;
  gap: 20px;
  display: flex;
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.calendar-custom-box-date-calendar-btns > .clear-btn {
  letter-spacing: .02em;
  color: var(--color-white);
  cursor: pointer;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
  text-underline-offset: 3px;
  background: none;
  border: none;
  flex-direction: row;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.calendar-custom-box-date-calendar-btns > .clear-btn:not(:disabled):hover {
  -webkit-text-decoration-color: var(--color-gray-400);
  text-decoration-color: var(--color-gray-400);
}

.calendar-custom-box-date-calendar-btns > .clear-btn:not(:disabled):active {
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
}

.calendar-custom-box-date-calendar-btns > .clear-btn:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.calendar-custom-box-date-calendar-btns > .done-btn {
  letter-spacing: .02em;
  background: var(--color-plum);
  color: var(--color-black);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  border: none;
  border-radius: 27px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 36px;
  padding: 0 12px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.calendar-custom-box-date-calendar-btns > .done-btn:not(:disabled):hover {
  background: linear-gradient(0deg, #ffffff1a, #ffffff1a), var(--color-plum);
}

.calendar-custom-box-date-calendar-btns > .done-btn:not(:disabled):active {
  background: linear-gradient(0deg, #0003, #0003), var(--color-plum);
}

.calendar-custom-box-date-calendar-btns > .done-btn > svg {
  transition: transform .2s;
}

@media only screen and (width >= 1024px) {
  .calendar-custom-box-date-calendar-btns > .done-btn {
    width: auto;
    padding: 8px 24px;
  }
}

.calendar-custom-box-date-calendar-btns > .done-btn:disabled {
  cursor: not-allowed;
  opacity: .3;
}

.copy-to-clipboard {
  cursor: pointer;
}

@media only screen and (width >= 1024px) {
  .copy-to-clipboard:hover {
    filter: brightness(1.2);
  }
}

.copy-to-clipboard-children {
  color: inherit;
  align-items: center;
  gap: 8px;
  display: flex;
}

.cross-chain {
  margin-bottom: 60px;
}

@media only screen and (width >= 1024px) {
  .cross-chain {
    border-radius: 16px;
    margin-bottom: 80px;
  }
}

.cross-chain-top {
  color: var(--color-white);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .cross-chain-top {
    margin-bottom: 28px;
  }
}

.cross-chain-top-fullscreen {
  cursor: pointer;
  transform: translateY(3px);
}

.cross-chain-top-title {
  letter-spacing: .02em;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .cross-chain-top-title {
    max-width: max-content;
  }
}

.cross-chain-top-title svg {
  color: var(--color-gray-600);
}

.cross-chain-top-title > a {
  letter-spacing: .02em;
  color: var(--color-white);
  cursor: pointer;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
  text-underline-offset: 3px;
  background: none;
  border: none;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.cross-chain-top-title > a:not(:disabled):hover {
  -webkit-text-decoration-color: var(--color-gray-400);
  text-decoration-color: var(--color-gray-400);
}

.cross-chain-top-title > a:not(:disabled):active {
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
}

.cross-chain-top-title > a:disabled {
  cursor: not-allowed;
  opacity: .5;
}

@media only screen and (width >= 1024px) {
  .cross-chain-top-title > a {
    margin-left: 8px;
  }
}

.cross-chain-top-download {
  display: none;
}

@media only screen and (width >= 1024px) {
  .cross-chain-top-download {
    display: flex;
  }
}

.cross-chain-options {
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 19px 0;
  width: 100%;
  min-height: 56px;
  padding-bottom: 36px;
  display: grid;
}

@media only screen and (width >= 1024px) {
  .cross-chain-options {
    background-color: var(--color-gray-950);
    border-radius: 16px 16px 0 0;
    gap: 24px;
    padding: 24px 24px 28px;
  }
}

.cross-chain-options > .toggle-group {
  grid-column: span 2;
  max-width: 100%;
}

@media only screen and (width >= 1024px) {
  .cross-chain-options > .toggle-group {
    grid-column: span 1;
  }
}

.cross-chain-options > .toggle-group > .toggle-group-root {
  width: 100%;
}

@media only screen and (width >= 1024px) {
  .cross-chain-options > .toggle-group > .toggle-group-root {
    width: auto;
  }
}

.cross-chain-options > .toggle-group > .toggle-group-root .toggle-group-item {
  justify-content: center;
  width: 100%;
}

.cross-chain-options-txsText {
  letter-spacing: .02em;
  color: var(--color-white);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.cross-chain-options-filters {
  flex-direction: column;
  grid-column: span 2;
  justify-content: center;
  gap: 32px;
  width: 100%;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .cross-chain-options-filters {
    grid-column: span 1;
  }
}

.cross-chain-options-filters-group {
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  display: grid;
}

@media only screen and (width >= 1024px) {
  .cross-chain-options-filters-group {
    width: inherit;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    display: flex;
  }
}

.cross-chain-options-filters-group .select__control {
  justify-content: center;
}

@media only screen and (width >= 1024px) {
  .cross-chain-options-filters-group .select__control {
    justify-content: space-between;
  }
}

.cross-chain-options-filters-group-download {
  max-width: 100%;
}

@media only screen and (width >= 1024px) {
  .cross-chain-options-filters-group-download {
    display: none;
  }

  .cross-chain-options-filters {
    width: inherit;
    flex-direction: row;
    align-items: center;
    display: flex;
  }
}

.cross-chain-chart {
  border-radius: 0 0 16px 16px;
  flex-direction: column;
  justify-content: center;
  min-height: 706px;
  padding-bottom: 24px;
  display: flex;
  position: relative;
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart {
    background-color: var(--color-gray-950);
  }
}

.cross-chain-chart .wormholescan-brand {
  filter: grayscale();
  height: 34px;
  min-height: unset;
  opacity: .2;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  width: 217px;
  margin: auto;
  position: absolute;
  inset: 0;
}

.cross-chain-chart-header {
  justify-content: space-between;
  margin-bottom: 16px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-header {
    padding: 0 24px;
  }
}

.cross-chain-chart-header > span {
  letter-spacing: .03em;
  color: var(--color-gray-400);
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.cross-chain-chart-header > span.selected {
  color: var(--color-white);
}

.cross-chain-chart-container {
  flex: 1;
  justify-content: space-between;
  min-height: 650px;
  display: flex;
  position: relative;
}

.cross-chain-chart-container-column {
  z-index: 1;
  width: 50%;
  height: 100%;
  position: relative;
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-column {
    width: 25%;
  }
}

.cross-chain-chart-container-column-item {
  appearance: none;
  color: inherit;
  font: inherit;
  text-align: start;
  cursor: pointer;
  background: none;
  border: none;
  grid-template-columns: auto auto auto auto;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin: 0;
  padding: 0 12px;
  transition: top .3s, height .3s;
  display: grid;
  position: absolute;
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-column-item {
    border-bottom: 1px solid var(--color-gray-900);
    grid-template-columns: 28px 5fr 1fr;
  }

  .cross-chain-chart-container-column-item:hover {
    background-color: var(--color-white-03);
  }
}

@media only screen and (width >= 1440px) {
  .cross-chain-chart-container-column-item {
    grid-template-columns: 28px 1.5fr 1fr 1fr;
  }
}

.cross-chain-chart-container-column-item:last-child {
  border: none;
}

.cross-chain-chart-container-column-item > .blockchain-icon {
  width: 16px;
  height: 16px;
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-column-item > .blockchain-icon {
    width: 24px;
    height: 24px;
  }
}

.cross-chain-chart-container-column-item > .chain {
  letter-spacing: .02em;
  opacity: .35;
  white-space: nowrap;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-column-item > .chain {
    opacity: 1;
  }
}

.cross-chain-chart-container-column-item > .separator {
  letter-spacing: .02em;
  opacity: .35;
  white-space: nowrap;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-column-item > .separator {
    opacity: 1;
    display: none;
  }
}

.cross-chain-chart-container-column-item > .volume {
  letter-spacing: .03em;
  color: var(--color-gray-400);
  flex-direction: row;
  justify-self: flex-end;
  align-items: center;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-column-item > .volume {
    letter-spacing: -.03em;
    color: var(--color-white);
    font-family: Roboto Mono, Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.cross-chain-chart-container-column-item > .percentage {
  letter-spacing: .03em;
  color: var(--color-gray-400);
  justify-self: end;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-column-item > .percentage {
    background-color: var(--color-gray-900);
    box-shadow: 0px 4px 4px 0px var(--color-black-25);
    color: var(--color-white);
    border-radius: 12px;
    flex-direction: row;
    align-items: center;
    height: 24px;
    margin-left: auto;
    padding: 0 8px;
    display: flex;
  }
}

.cross-chain-chart-container-column-item-tooltip {
  flex-direction: column;
  justify-content: flex-start;
  width: calc(50vw - 16px);
  min-width: min-content;
  display: flex;
  transform: translateY(12px);
  padding: 0 !important;
}

@media only screen and (width >= 768px) {
  .cross-chain-chart-container-column-item-tooltip {
    width: calc(50vw - 60px);
  }
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-column-item-tooltip {
    width: calc(25vw - 60px);
  }
}

.cross-chain-chart-container-column-item-tooltip-element {
  border-bottom: 1px solid var(--color-gray-950);
  height: 28px;
  position: relative;
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-column-item-tooltip-element {
    height: 32px;
  }
}

.cross-chain-chart-container-column-item-tooltip-element:after {
  display: none;
}

.cross-chain-chart-container-column-item-tooltip-element:first-of-type {
  border-radius: 16px 16px 0 0;
}

.cross-chain-chart-container-column-item-tooltip-element:last-of-type {
  border: none;
  border-radius: 0 0 16px 16px;
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-column-item-tooltip-element > .volume {
    display: none !important;
  }
}

@media only screen and (width >= 1440px) {
  .cross-chain-chart-container-column-item-tooltip-element > .volume {
    display: block !important;
  }
}

.cross-chain-chart-container-column-item.other-item {
  cursor: default;
  border: none;
}

.cross-chain-chart-container-column-item.other-item:hover {
  background-color: #0000;
}

.cross-chain-chart-container-column-item.other-item > .icons {
  flex-direction: row;
  align-items: center;
  max-width: 50px;
  display: flex;
  overflow: hidden;
}

.cross-chain-chart-container-column-item.other-item > .icons > .blockchain-icon {
  width: 16px;
  height: 16px;
  margin-right: -9px;
}

.cross-chain-chart-container-column-item.showPercentageMobile > .volume, .cross-chain-chart-container-column-item.showMoneyMobile > .percentage, .cross-chain-chart-container-column-item.showMoneyMobile > .chain {
  display: none;
}

@media only screen and (width >= 768px) {
  .cross-chain-chart-container-column-item.showMoneyMobile > .chain {
    display: block;
  }
}

.cross-chain-chart-container-column-item.left:after {
  background-color: var(--color-white-10);
  clip-path: inset(0 0 2px);
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-column-item.left:after {
    display: none;
  }

  .cross-chain-chart-container-column-item.left > .volume {
    position: absolute;
    left: 102%;
  }
}

@media only screen and (width >= 1440px) {
  .cross-chain-chart-container-column-item.left > .volume {
    left: unset;
    position: relative;
  }
}

.cross-chain-chart-container-column-item.right {
  justify-content: flex-end;
}

.cross-chain-chart-container-column-item.right:after {
  background-color: var(--color-white-10);
  clip-path: inset(0 0 2px);
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-column-item.right:after {
    display: none;
  }

  .cross-chain-chart-container-column-item.right > .volume {
    position: absolute;
    right: 102%;
  }
}

@media only screen and (width >= 1440px) {
  .cross-chain-chart-container-column-item.right > .volume {
    right: unset;
    position: relative;
  }
}

.cross-chain-chart-container-column-item.selected {
  cursor: default;
}

.cross-chain-chart-container-column-item.selected.left:after {
  background-color: var(--color-plum);
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-column-item.selected.left:after {
    display: none;
  }

  .cross-chain-chart-container-column-item.selected.left {
    background: linear-gradient(270deg, var(--color-plum-12) 25%, transparent 100%);
  }
}

.cross-chain-chart-container-column-item.selected.right:after {
  background-color: var(--color-plum);
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-column-item.selected.right:after {
    display: none;
  }

  .cross-chain-chart-container-column-item.selected.right {
    background: linear-gradient(90deg, var(--color-plum-12) 25%, transparent 100%);
  }
}

.cross-chain-chart-container-column-item.selected > .chain, .cross-chain-chart-container-column-item.selected > .separator {
  opacity: 1;
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-column-item.selected > .percentage {
    background-color: var(--color-lime);
    box-shadow: unset;
    color: var(--color-black);
  }
}

.cross-chain-chart-container.left-selected .right .chain, .cross-chain-chart-container.left-selected .right .separator, .cross-chain-chart-container-column-item-tooltip.right .right .chain, .cross-chain-chart-container-column-item-tooltip.right .right .separator {
  opacity: 1;
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container.left-selected .right .percentage, .cross-chain-chart-container-column-item-tooltip.right .right .percentage {
    background-color: var(--color-plum-12);
    box-shadow: unset;
    color: var(--color-lime);
  }
}

.cross-chain-chart-container.left-selected .left:not(.selected) .blockchain-icon, .cross-chain-chart-container-column-item-tooltip.right .left:not(.selected) .blockchain-icon {
  filter: grayscale();
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container.left-selected .left:not(.selected) .blockchain-icon, .cross-chain-chart-container-column-item-tooltip.right .left:not(.selected) .blockchain-icon {
    filter: unset;
  }
}

.cross-chain-chart-container.left-selected .left:not(.selected) .volume, .cross-chain-chart-container-column-item-tooltip.right .left:not(.selected) .volume {
  color: var(--color-gray-400);
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container.left-selected .left:not(.selected) .volume, .cross-chain-chart-container-column-item-tooltip.right .left:not(.selected) .volume {
    display: none;
  }
}

@media only screen and (width >= 1440px) {
  .cross-chain-chart-container.left-selected .left:not(.selected) .volume, .cross-chain-chart-container-column-item-tooltip.right .left:not(.selected) .volume {
    display: block;
  }
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-column-item-tooltip.right .left:not(.selected) .volume {
    display: block;
  }
}

.cross-chain-chart-container.right-selected .left .chain, .cross-chain-chart-container.right-selected .left .separator, .cross-chain-chart-container-column-item-tooltip.left .left .chain, .cross-chain-chart-container-column-item-tooltip.left .left .separator {
  opacity: 1;
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container.right-selected .left .percentage, .cross-chain-chart-container-column-item-tooltip.left .left .percentage {
    background-color: var(--color-plum-12);
    box-shadow: unset;
    color: var(--color-lime);
  }
}

.cross-chain-chart-container.right-selected .right:not(.selected) .blockchain-icon, .cross-chain-chart-container-column-item-tooltip.left .right:not(.selected) .blockchain-icon {
  filter: grayscale();
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container.right-selected .right:not(.selected) .blockchain-icon, .cross-chain-chart-container-column-item-tooltip.left .right:not(.selected) .blockchain-icon {
    filter: unset;
  }
}

.cross-chain-chart-container.right-selected .right:not(.selected) .volume, .cross-chain-chart-container-column-item-tooltip.left .right:not(.selected) .volume {
  color: var(--color-gray-400);
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container.right-selected .right:not(.selected) .volume, .cross-chain-chart-container-column-item-tooltip.left .right:not(.selected) .volume {
    display: none;
  }
}

@media only screen and (width >= 1440px) {
  .cross-chain-chart-container.right-selected .right:not(.selected) .volume, .cross-chain-chart-container-column-item-tooltip.left .right:not(.selected) .volume {
    display: block;
  }
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-column-item-tooltip.left .right:not(.selected) .volume {
    display: block;
  }
}

.cross-chain-chart-container-svg {
  width: 100%;
  position: absolute;
}

@media only screen and (width >= 1024px) {
  .cross-chain-chart-container-svg {
    width: 50%;
    position: relative;
  }
}

.cross-chain-chart-container-svg-group-path {
  animation: 1s ease-out forwards scaleTo1;
  transform: scaleX(.7);
}

.cross-chain-chart-container-svg-group-path.source {
  transform-origin: 0;
}

.cross-chain-chart-container-svg-group-path.target {
  transform-origin: 100%;
}

.cross-chain-mobile {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
  margin: 8px 0 0 auto;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .cross-chain-mobile {
    display: none;
  }
}

.cross-chain-mobile-btn {
  color: var(--color-white-60);
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-radius: 12px;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  display: flex;
}

.cross-chain-mobile-btn.active {
  background-color: var(--color-gray-900);
  color: var(--color-white);
}

@keyframes scaleTo1 {
  to {
    transform: scaleX(1);
  }
}

.error-placeholder {
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 48px 16px;
  display: flex;
}

.error-placeholder-image {
  margin-bottom: 16px;
}

.error-placeholder-image img {
  width: 100px;
  height: 78px;
  display: block;
}

.error-placeholder-text {
  letter-spacing: .02em;
  text-align: center;
  max-width: 165px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.footer {
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  margin: 0 0 96px;
  display: flex;
}

@media only screen and (width >= 768px) {
  .footer {
    margin: 0 64px 96px;
  }
}

@media only screen and (width >= 1024px) {
  .footer {
    gap: 86px;
    width: 100%;
    margin: 0 auto 58px;
  }
}

.footer-container {
  grid-template-columns: auto auto;
  justify-content: space-between;
  gap: 40px 0;
  width: 100%;
  display: grid;
}

@media only screen and (width >= 1024px) {
  .footer-container {
    flex-direction: row;
    align-items: flex-start;
    display: flex;
  }
}

.footer-container-discord {
  background-color: var(--color-gray-950);
  color: var(--color-gray-600);
  border-radius: 16px;
  flex-direction: column;
  grid-column: span 2;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 16px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .footer-container-discord {
    border-radius: 20px;
    flex-direction: row;
    align-items: center;
    display: flex;
  }
}

.footer-container-discord-text {
  letter-spacing: .02em;
  color: var(--color-white);
  max-width: 240px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@media only screen and (width >= 1024px) {
  .footer-container-discord-text {
    max-width: unset;
  }
}

.footer-container-discord-links {
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 14px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .footer-container-discord-links {
    margin: 0 0 0 auto;
  }
}

.footer-container-discord-links-ds-btn {
  letter-spacing: .02em;
  background: var(--color-plum);
  color: var(--color-black);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  border: none;
  border-radius: 27px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  height: 36px;
  padding: 0 12px 0 8px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.footer-container-discord-links-ds-btn:not(:disabled):hover {
  background: linear-gradient(0deg, #ffffff1a, #ffffff1a), var(--color-plum);
}

.footer-container-discord-links-ds-btn:not(:disabled):active {
  background: linear-gradient(0deg, #0003, #0003), var(--color-plum);
}

.footer-container-discord-links-ds-btn:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.footer-container-discord-links-ds-btn > svg {
  color: var(--color-black-60);
  transition: transform .2s;
}

.footer-container-discord-links-x-btn {
  letter-spacing: .02em;
  background: var(--color-plum);
  color: var(--color-white);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background: var(--color-gray-900);
  border: none;
  border-radius: 27px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  height: 36px;
  padding: 0 12px 0 8px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.footer-container-discord-links-x-btn:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.footer-container-discord-links-x-btn > svg {
  transition: transform .2s;
}

.footer-container-discord-links-x-btn:not(:disabled):hover {
  background: var(--color-gray-800);
}

.footer-container-discord-links-x-btn:not(:disabled):active {
  background: var(--color-gray-900);
}

.footer-container-discord-links-x-btn > svg {
  color: var(--color-white-60);
}

.footer-container-button {
  letter-spacing: .02em;
  background: var(--color-plum);
  color: var(--color-white);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background: var(--color-gray-900);
  border: none;
  border-radius: 27px;
  flex-direction: row;
  grid-area: 1 / 2 / 2 / 3;
  align-items: center;
  gap: 4px;
  max-width: max-content;
  height: 36px;
  margin-left: auto;
  padding: 0 8px 0 12px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.footer-container-button:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.footer-container-button > svg {
  transition: transform .2s;
}

.footer-container-button:not(:disabled):hover {
  background: var(--color-gray-800);
}

.footer-container-button:not(:disabled):active {
  background: var(--color-gray-900);
}

@media only screen and (width >= 1024px) {
  .footer-container-button {
    margin-left: 0;
  }
}

.footer-container-button > svg {
  color: var(--color-white-60);
}

.footer-container-links {
  border-bottom: 1px solid var(--color-gray-950);
  white-space: nowrap;
  grid-column: span 2;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px 0;
  width: 100%;
  padding-bottom: 32px;
  display: grid;
}

@media only screen and (width >= 1024px) {
  .footer-container-links {
    border: none;
    grid-template-columns: repeat(3, auto);
    justify-content: space-between;
    width: 45%;
    padding-bottom: 0;
  }
}

.footer-container-links-container {
  flex-direction: column;
  display: flex;
}

.footer-container-links-container > div {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
}

.footer-container-links-container-title {
  letter-spacing: .03em;
  color: var(--color-gray-400);
  text-transform: uppercase;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
}

.footer-container-links-container-title-icon {
  margin-right: 6px;
  font-size: 16px;
}

.footer-container-links-container > a {
  letter-spacing: .02em;
  color: var(--color-gray-200);
  cursor: pointer;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
  text-underline-offset: 3px;
  letter-spacing: .02em;
  background: none;
  border: none;
  flex-direction: row;
  align-items: center;
  max-width: max-content;
  min-height: 25px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
  display: flex;
}

.footer-container-links-container > a:not(:disabled):hover {
  -webkit-text-decoration-color: var(--color-gray-400);
  text-decoration-color: var(--color-gray-400);
}

.footer-container-links-container > a:not(:disabled):active {
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
}

.footer-container-links-container > a:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.footer-container-links-container > a:hover {
  text-decoration: underline;
}

.footer-container-build {
  letter-spacing: .02em;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.footer-container-build-text {
  color: var(--color-gray-400);
}

.footer-container-build > a {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.footer-container-build > a:hover {
  opacity: .6;
}

.footer-container-copy {
  color: var(--color-gray-600);
  flex-flow: wrap;
  align-items: center;
  max-width: 220px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .footer-container-copy {
    max-width: unset;
    margin: auto 0;
  }
}

.footer-container-copy-text {
  letter-spacing: .03em;
  text-transform: uppercase;
  margin: 0 16px 0 6px;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.footer-container-copy > a {
  letter-spacing: .02em;
  color: var(--color-gray-200);
  cursor: pointer;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
  text-underline-offset: 3px;
  letter-spacing: .02em;
  background: none;
  border: none;
  flex-direction: row;
  align-items: center;
  max-width: max-content;
  min-height: 25px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
  display: flex;
}

.footer-container-copy > a:not(:disabled):hover {
  -webkit-text-decoration-color: var(--color-gray-400);
  text-decoration-color: var(--color-gray-400);
}

.footer-container-copy > a:not(:disabled):active {
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
}

.footer-container-copy > a:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.footer-container-copy > a:hover {
  text-decoration: underline;
}

.footer .desktop {
  display: none;
}

@media only screen and (width >= 1024px) {
  .footer .desktop {
    display: flex;
  }

  .header {
    height: 172px;
  }

  .header-secondary {
    height: 52px;
  }
}

.header .new-icon {
  letter-spacing: .03em;
  background-color: var(--color-plum);
  color: var(--color-black);
  border-radius: 2px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 14px;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  position: relative;
}

.header .new-icon:before {
  background-color: var(--color-plum);
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  left: -1.5px;
  rotate: 45deg;
}

@media only screen and (width >= 1024px) {
  .header .new-icon:before {
    bottom: -1.5px;
    left: unset;
  }
}

.header .new-icon-multigov {
  position: absolute;
  transform: translate(40px, -18px);
}

.header-banner {
  z-index: 999;
  width: 100%;
  transition: bottom .3s ease-in-out;
  position: fixed;
  bottom: 0;
  left: 0;
}

.header-banner.show-mobile-nav {
  bottom: 76px;
}

.header-banner.show-mobile-analytics {
  bottom: 252px;
}

.header-banner.show-mobile-other {
  bottom: 404px;
}

.header-container {
  z-index: 99;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 28px 0;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 40px;
  display: grid;
}

@media only screen and (width >= 1024px) {
  .header-container {
    gap: 20px 12px;
    margin-bottom: 30px;
  }
}

.header-container-mobile {
  z-index: 99;
  height: 76px;
  transition: transform .3s ease-in-out;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media only screen and (width >= 1024px) {
  .header-container-mobile {
    display: none;
  }
}

.header-container-mobile.hidden {
  transform: translateY(120%);
}

.header-container-mobile-items {
  background-color: var(--color-gray-900);
  z-index: 99;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px 12px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.header-container-mobile-items > .navlink {
  letter-spacing: .03em;
  color: var(--color-white);
  cursor: pointer;
  opacity: .5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 32px;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.header-container-mobile-items > .navlink.active {
  opacity: 1;
}

.header-container-mobile-menu {
  background-color: var(--color-gray-950);
  z-index: 97;
  border-radius: 12px 12px 0 0;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  padding: 28px 32px;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(calc(100% - 76px));
}

.header-container-mobile-menu-wIcon {
  margin-left: 4px;
  margin-right: 3px;
}

.header-container-mobile-menu.open {
  z-index: 98;
  transform: translateY(-76px);
}

.header-container-mobile-menu-dev-tools {
  border-left: 1px solid var(--color-gray-900);
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  padding-left: 16px;
  display: flex;
}

.header-container-mobile-menu-dev-tools-title {
  letter-spacing: .02em;
  color: var(--color-gray-500);
  margin-top: 8px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.header-container-mobile-menu .navlink {
  letter-spacing: .02em;
  color: var(--color-gray-200);
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .header-container-mobile-menu .navlink {
    color: var(--color-white-80);
    opacity: .5;
  }
}

.header-container-mobile-menu .navlink:hover, .header-container-mobile-menu .navlink.active {
  color: var(--color-white);
}

@media only screen and (width >= 1024px) {
  .header-container-mobile-menu .navlink.active {
    background-color: var(--color-gray-800);
  }
}

.header-container-mobile-menu .navlink > svg {
  color: var(--color-gray-400);
}

.header-container-logo {
  grid-column: 1;
  max-width: max-content;
}

.header-container-logo img {
  width: 250px;
}

.header-container > .search-bar {
  grid-area: 2 / 1 / auto / span 2;
}

.header-container-links {
  grid-column: 2;
  justify-self: flex-end;
  margin-top: auto;
}

@media only screen and (width >= 1024px) {
  .header-container-links {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-top: 0;
    display: flex;
  }
}

.header-container-links > nav {
  display: none;
}

@media only screen and (width >= 1024px) {
  .header-container-links > nav {
    display: flex;
  }
}

.header-container-links a {
  display: none;
}

@media only screen and (width >= 1024px) {
  .header-container-links a {
    letter-spacing: .03em;
    color: var(--color-gray-200);
    text-transform: uppercase;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;
    font-family: Roboto Mono, Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-decoration: none;
    display: flex;
  }

  .header-container-links a.active, .header-container-links a:hover {
    color: var(--color-white);
  }
}

.header-container .dropdown-menu {
  white-space: nowrap;
}

.header-container .dropdown-menu > li {
  display: block;
}

.header-container .dropdown-menu-trigger {
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  display: flex;
  position: relative;
}

.header-container .dropdown-menu-trigger .icon {
  margin-left: 8px;
}

.header-container .dropdown-menu-trigger[data-state="open"] {
  color: var(--color-white);
}

.header-container .dropdown-menu-trigger[data-state="open"] .icon {
  rotate: 180deg;
}

.header-container .dropdown-menu-trigger.active {
  color: var(--color-white);
}

.header-container .dropdown-menu-content {
  z-index: 100;
  border-radius: 12px;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding-top: 8px;
  display: flex;
  position: absolute;
}

.header-container .dropdown-menu-content-wIcon {
  margin-left: 4px;
  margin-right: 2px;
}

.header-container .dropdown-menu-content > a {
  letter-spacing: .02em;
  background-color: var(--color-gray-900);
  text-transform: capitalize;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 24px 8px 8px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.header-container .dropdown-menu-content > a > svg {
  color: var(--color-gray-400);
}

.header-container .dropdown-menu-content > a.active {
  background-color: var(--color-gray-800);
}

.header-container .dropdown-menu-content > a:first-child {
  border-radius: 12px 12px 0 0;
}

.header-container .dropdown-menu-content > a:last-child {
  border-radius: 0 0 12px 12px;
}

.header-container .dropdown-menu button {
  letter-spacing: .03em;
  color: var(--color-gray-200);
  text-transform: uppercase;
  background: none;
  border: 0;
  width: 100%;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-decoration: none;
}

.header-container .dropdown-menu button[data-state="open"] {
  color: var(--color-white);
}

.header-container-secondary > .search-bar {
  display: none;
}

@media only screen and (width >= 1024px) {
  .header-container-secondary > .search-bar {
    box-shadow: none;
    border-radius: 12px;
    grid-area: 1 / 2;
    width: 90%;
    height: 36px;
    margin: 0 auto;
    display: block;
  }

  .header-container-secondary > .search-bar > .search-bar-input > input {
    letter-spacing: .03em;
    text-transform: uppercase;
    font-family: Roboto Mono, Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .header-container-secondary {
    grid-template-columns: auto 1fr auto;
  }

  .header-container-secondary > .header-container-logo {
    grid-column: 1;
  }

  .header-container-secondary > .header-container-links {
    grid-column: 3;
  }

  .header-container-fixed {
    background-color: var(--color-gray-900);
    z-index: 99;
    grid-template-columns: auto 1fr auto;
    width: auto;
    height: 56px;
    margin-top: 0;
    padding: 0 120px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .header-container-fixed > .header-container-logo {
    grid-column: 1;
  }

  .header-container-fixed > .search-bar {
    box-shadow: none;
    background-color: #0000;
    border-radius: 0;
    grid-area: 1 / 2;
    height: 54px;
  }

  .header-container-fixed > .search-bar > .search-bar-input > input {
    letter-spacing: .03em;
    text-transform: uppercase;
    font-family: Roboto Mono, Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .header-container-fixed > .header-container-links {
    grid-column: 3;
  }
}

.search-bar {
  background-color: var(--color-gray-950);
  border-radius: 20px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 68px;
  display: flex;
  box-shadow: 0 2px 6px #0000000d, 0 20px 60px #00000040;
}

.search-bar-input {
  height: inherit;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.search-bar-input > svg {
  color: var(--color-white);
  opacity: .5;
  position: absolute;
  left: 16px;
}

.search-bar-input > input {
  letter-spacing: .02em;
  color: var(--color-white);
  height: inherit;
  z-index: 1;
  background-color: #0000;
  border: none;
  width: 100%;
  padding: 0 16px 0 48px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.search-bar-input > input::placeholder {
  color: var(--color-white);
  opacity: .5;
}

.search-bar-input > input:focus {
  outline: none;
}

.search-bar .search-loader {
  box-sizing: border-box;
  opacity: .5;
  border: 2px solid;
  border-bottom-color: #0000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: 1s linear infinite rotation;
  display: inline-block;
  position: absolute;
  left: 20px;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.protocols-activity {
  flex-direction: column;
  justify-content: center;
  margin-bottom: 48px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .protocols-activity {
    margin-bottom: 80px;
  }
}

.protocols-activity-bg {
  background-color: var(--color-black-50);
  z-index: 77;
  position: fixed;
  inset: 0;
}

@media only screen and (width >= 1024px) {
  .protocols-activity-bg {
    display: none;
  }
}

.protocols-activity-title {
  letter-spacing: .02em;
  color: var(--color-white);
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.protocols-activity-title-fullscreen {
  cursor: pointer;
  transform: translateY(3px);
}

.protocols-activity-title svg {
  color: var(--color-gray-600);
}

.protocols-activity-container {
  background-color: var(--color-gray-950);
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 48px;
  padding: 16px 16px 0;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .protocols-activity-container {
    padding: 24px 24px 0;
  }
}

.protocols-activity-container-top {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.protocols-activity-container-top-mobile-filters-btn {
  letter-spacing: .02em;
  background: var(--color-plum);
  color: var(--color-white);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background: var(--color-gray-900);
  border: none;
  border-radius: 27px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  height: 36px;
  padding: 0 12px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.protocols-activity-container-top-mobile-filters-btn:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.protocols-activity-container-top-mobile-filters-btn > svg {
  transition: transform .2s;
}

.protocols-activity-container-top-mobile-filters-btn:not(:disabled):hover {
  background: var(--color-gray-800);
}

.protocols-activity-container-top-mobile-filters-btn:not(:disabled):active {
  background: var(--color-gray-900);
}

@media only screen and (width >= 1024px) {
  .protocols-activity-container-top-mobile-filters-btn {
    display: none;
  }
}

.protocols-activity-container-top-mobile-filters-btn > svg {
  color: var(--color-white-60);
}

.protocols-activity-container-top-filters {
  background-color: var(--color-gray-950);
  border: 1px solid var(--color-gray-900);
  z-index: 99;
  border-bottom: none;
  border-radius: 40px 40px 0 0;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  padding: 20px;
  transition: inset .3s;
  display: flex;
  position: fixed;
  inset: 120vh 0 0;
}

@media only screen and (width >= 1024px) {
  .protocols-activity-container-top-filters {
    inset: unset;
    z-index: unset;
    border: none;
    border-radius: 16px;
    flex-flow: wrap-reverse;
    align-items: center;
    padding: 0;
    display: flex;
    position: relative;
  }
}

.protocols-activity-container-top-filters.open {
  inset: max(16px, 100% - 708px) 0 0;
}

.protocols-activity-container-top-filters-title {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .protocols-activity-container-top-filters-title {
    display: none;
  }
}

.protocols-activity-container-top-filters-title > button {
  letter-spacing: .02em;
  background: var(--color-plum);
  color: var(--color-white);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background: var(--color-gray-900);
  background-color: var(--color-gray-800);
  border: none;
  border-radius: 27px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 24px;
  height: 24px;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: flex;
  position: absolute;
  right: 20px;
}

.protocols-activity-container-top-filters-title > button:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.protocols-activity-container-top-filters-title > button > svg {
  transition: transform .2s;
}

.protocols-activity-container-top-filters-title > button:not(:disabled):hover {
  background: var(--color-gray-800);
}

.protocols-activity-container-top-filters-title > button:not(:disabled):active {
  background: var(--color-gray-900);
}

.protocols-activity-container-top-filters-protocol {
  width: 100%;
}

@media only screen and (width >= 1024px) {
  .protocols-activity-container-top-filters-protocol {
    width: max-content;
  }
}

.protocols-activity-container-top-filters-protocol .select-searchable-dropdown {
  justify-content: space-between;
  width: 100%;
  height: 48px;
}

@media only screen and (width >= 1024px) {
  .protocols-activity-container-top-filters-protocol .select-searchable-dropdown {
    width: max-content;
    height: 36px;
  }
}

.protocols-activity-container-top-filters-range {
  height: 48px;
}

@media only screen and (width >= 1024px) {
  .protocols-activity-container-top-filters-range {
    height: 36px;
  }
}

.protocols-activity-container-top-filters-range .select__control {
  height: inherit;
}

.protocols-activity-container-top-filters-metric {
  max-width: 100%;
  height: 48px;
}

@media only screen and (width >= 1024px) {
  .protocols-activity-container-top-filters-metric {
    order: 3;
    height: 36px;
    margin: 0 94px 0 auto;
  }
}

.protocols-activity-container-top-filters-metric .toggle-group-root {
  height: inherit;
  width: 100%;
}

.protocols-activity-container-top-filters-metric .toggle-group-root .toggle-group-item {
  height: inherit;
  justify-content: center;
  width: 100%;
}

.protocols-activity-container-top-filters-btns {
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  margin-top: auto;
  display: flex;
}

.protocols-activity-container-top-filters-btns-apply {
  letter-spacing: .02em;
  background: var(--color-plum);
  color: var(--color-black);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  border: none;
  border-radius: 27px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 48px;
  padding: 0 12px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.protocols-activity-container-top-filters-btns-apply:not(:disabled):hover {
  background: linear-gradient(0deg, #ffffff1a, #ffffff1a), var(--color-plum);
}

.protocols-activity-container-top-filters-btns-apply:not(:disabled):active {
  background: linear-gradient(0deg, #0003, #0003), var(--color-plum);
}

.protocols-activity-container-top-filters-btns-apply:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.protocols-activity-container-top-filters-btns-apply > svg {
  transition: transform .2s;
}

@media only screen and (width >= 1024px) {
  .protocols-activity-container-top-filters-btns-apply {
    display: none;
  }
}

.protocols-activity-container-top-filters-btns-reset {
  letter-spacing: .02em;
  color: var(--color-white);
  cursor: pointer;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
  text-underline-offset: 3px;
  background: none;
  border: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.protocols-activity-container-top-filters-btns-reset:not(:disabled):hover {
  -webkit-text-decoration-color: var(--color-gray-400);
  text-decoration-color: var(--color-gray-400);
}

.protocols-activity-container-top-filters-btns-reset:not(:disabled):active {
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
}

.protocols-activity-container-top-filters-btns-reset:disabled {
  cursor: not-allowed;
  opacity: .5;
}

@media only screen and (width >= 1024px) {
  .protocols-activity-container-top-filters-btns-reset {
    height: 36px;
    margin: 0;
  }

  .protocols-activity-container-top-toggle-design {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.protocols-activity-container-chart {
  min-height: 363px;
  position: relative;
}

@media only screen and (width >= 1024px) {
  .protocols-activity-container-chart {
    min-height: 423px;
  }
}

.protocols-activity-container-chart .loader {
  margin-top: 64px;
}

@media only screen and (width >= 1024px) {
  .protocols-activity-container-chart .loader {
    margin-top: 128px;
  }
}

.protocols-activity-container-chart-header {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 4px 0;
  min-height: 24px;
  margin-top: 24px;
  display: flex;
}

.protocols-activity-container-chart-header-total-txt {
  letter-spacing: .02em;
  color: var(--color-gray-500);
  flex-direction: row;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.protocols-activity-container-chart-header-total-txt > span {
  letter-spacing: -.03em;
  color: var(--color-white);
  margin-left: 4px;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.protocols-activity-container-chart .wormholescan-brand {
  filter: grayscale();
  height: 34px;
  min-height: unset;
  opacity: .2;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  width: 162px;
  margin: auto;
  position: absolute;
  inset: 0 0 24px;
}

@media only screen and (width >= 1024px) {
  .protocols-activity-container-chart .wormholescan-brand {
    width: 217px;
    bottom: 8px;
  }
}

.protocols-activity-container-chart-scale {
  position: absolute;
  top: -35px;
  right: 88px;
}

@media only screen and (width >= 1024px) {
  .protocols-activity-container-chart-scale {
    top: -35px;
    right: 276px;
  }
}

.protocols-activity-container-chart-tooltip {
  background-color: var(--color-gray-900);
  border-radius: 16px;
  width: max-content;
  padding: 12px 16px 16px;
}

.protocols-activity-container-chart-tooltip-date {
  letter-spacing: .03em;
  color: var(--color-gray-400);
  text-transform: uppercase;
  flex-direction: column;
  justify-content: center;
  gap: 4px 0;
  margin-bottom: 12px;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  font-family: Roboto Mono, Roboto, sans-serif !important;
}

.protocols-activity-container-chart-tooltip-protocol {
  flex-direction: row;
  align-items: center;
  gap: 10px;
  display: flex;
}

.protocols-activity-container-chart-tooltip-protocol-icon {
  background-color: var(--color-lime);
  border-radius: 3px;
  width: 4px;
  height: 12px;
}

.protocols-activity-container-chart-tooltip-protocol-name {
  letter-spacing: .02em;
  flex-direction: row;
  align-items: center;
  gap: 0 8px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  font-family: Roboto, sans-serif !important;
}

.protocols-activity-container-chart-tooltip-protocol-name-percentage {
  letter-spacing: .03em;
  color: var(--color-gray-500);
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  font-family: Roboto Mono, Roboto, sans-serif !important;
}

.protocols-activity-container-chart-tooltip-protocol-number {
  letter-spacing: -.03em;
  margin-left: auto;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto Mono, Roboto, sans-serif !important;
}

.protocols-stats {
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 60px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .protocols-stats {
    margin-bottom: 80px;
  }

  .protocols-stats.Testnet .protocols-stats-container-header, .protocols-stats.Testnet .protocols-stats-container-element {
    grid-template-columns: minmax(270px, 1.4fr) minmax(130px, 1fr) minmax(120px, 1fr) minmax(170px, 1fr);
  }
}

.protocols-stats-title {
  letter-spacing: .02em;
  color: var(--color-white);
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.protocols-stats-title > svg {
  color: var(--color-gray-600);
}

.protocols-stats-title > a {
  letter-spacing: .02em;
  color: var(--color-white);
  cursor: pointer;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
  text-underline-offset: 3px;
  background: none;
  border: none;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.protocols-stats-title > a:not(:disabled):hover {
  -webkit-text-decoration-color: var(--color-gray-400);
  text-decoration-color: var(--color-gray-400);
}

.protocols-stats-title > a:not(:disabled):active {
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
}

.protocols-stats-title > a:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.protocols-stats-container {
  scroll-snap-type: x mandatory;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  display: flex;
  overflow-x: auto;
}

@media only screen and (width >= 1024px) {
  .protocols-stats-container {
    flex-direction: column;
    justify-content: center;
    align-items: normal;
    gap: 0;
    display: flex;
  }
}

.protocols-stats-container-header {
  display: none;
}

@media only screen and (width >= 1024px) {
  .protocols-stats-container-header {
    background-color: var(--color-gray-950);
    white-space: nowrap;
    border-radius: 16px;
    grid-template-columns: minmax(270px, 1fr) minmax(130px, 1fr) minmax(170px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(170px, 1fr);
    gap: 16px;
    min-width: fit-content;
    padding: 12px 16px;
    display: grid;
  }

  .protocols-stats-container-header-title {
    letter-spacing: .03em;
    color: var(--color-gray-400);
    text-transform: uppercase;
    flex-direction: row;
    align-items: center;
    gap: 0 4px;
    height: 16px;
    font-family: Roboto Mono, Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    display: flex;
  }

  .protocols-stats-container-header-title:last-child {
    justify-content: end;
  }

  .protocols-stats-container-header-title-tooltip {
    flex-direction: row;
    align-items: center;
    display: flex;
  }
}

.protocols-stats-container-element {
  background-color: var(--color-gray-950);
  border: 1px solid var(--color-gray-900);
  scroll-snap-align: center;
  white-space: nowrap;
  border-radius: 16px;
  flex: 3;
  gap: 32px 16px;
  min-width: 100%;
  padding: 16px;
  display: grid;
}

@media only screen and (width >= 1024px) {
  .protocols-stats-container-element {
    border: none;
    border-bottom: 1px solid var(--color-gray-900);
    scroll-snap-align: unset;
    background-color: #0000;
    border-radius: 0;
    grid-template-columns: minmax(270px, 1fr) minmax(130px, 1fr) minmax(170px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(170px, 1fr);
    gap: 16px;
    min-width: fit-content;
    padding: 24px 16px;
  }
}

.protocols-stats-container-element.ntt {
  cursor: pointer;
}

.protocols-stats-container-element.ntt:hover {
  background-color: var(--color-gray-975);
}

.protocols-stats-container-element.ntt:hover .protocols-stats-container-element-item-value-chains-chain {
  border-color: var(--color-gray-975);
}

@media only screen and (width >= 1024px) {
  .protocols-stats-container-element-loader {
    border-bottom: 1px solid var(--color-gray-900);
    height: 77px;
    position: relative;
    overflow: hidden;
  }

  .protocols-stats-container-element-loader:before {
    background-image: linear-gradient(120deg, var(--color-white-05) 0%, var(--color-white-10) 25%, var(--color-white-05) 50%, var(--color-white-10) 75%, var(--color-white-05) 100%);
    content: "";
    width: 400%;
    height: 28px;
    margin: auto 0;
    animation: 2s linear infinite loading-list;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
}

.protocols-stats-container-element-item {
  color: var(--color-white);
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.protocols-stats-container-element-item:first-child {
  flex-direction: row;
  grid-column: span 2;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .protocols-stats-container-element-item:first-child {
    grid-column: span 1;
  }

  .protocols-stats-container-element-item.mobile {
    display: none;
  }
}

.protocols-stats-container-element-item.desktop {
  display: none;
}

@media only screen and (width >= 1024px) {
  .protocols-stats-container-element-item.desktop {
    display: block;
  }
}

.protocols-stats-container-element-item-link {
  color: var(--color-white);
  flex-direction: row;
  align-items: center;
  gap: 8px 12px;
  text-decoration: none;
  display: flex;
}

.protocols-stats-container-element-item-link:hover {
  text-decoration: underline;
}

.protocols-stats-container-element-item-link-protocol {
  letter-spacing: .02em;
  color: var(--color-white);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.protocols-stats-container-element-item-tooltip {
  color: var(--color-gray-400);
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
  display: flex;
}

.protocols-stats-container-element-item-tooltip-text {
  letter-spacing: -.03em;
  color: var(--color-white);
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.protocols-stats-container-element-item-tooltip-text > span {
  letter-spacing: .03em;
  color: var(--color-gray-400);
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.protocols-stats-container-element-item-title {
  letter-spacing: .03em;
  color: var(--color-gray-400);
  flex-direction: row;
  align-items: center;
  gap: 0 4px;
  height: 16px;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .protocols-stats-container-element-item-title {
    display: none;
  }
}

.protocols-stats-container-element-item-value {
  letter-spacing: -.03em;
  color: var(--color-white);
  flex-direction: row;
  align-items: center;
  gap: 8px;
  min-height: 28px;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.protocols-stats-container-element-item-value-diff {
  letter-spacing: .03em;
  color: var(--color-gray-400);
  border-radius: 12px;
  align-items: center;
  padding: 6px 8px;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
}

.protocols-stats-container-element-item-value-diff.negative {
  background-color: var(--color-error-15);
  color: var(--color-error-100);
}

.protocols-stats-container-element-item-value-diff.positive {
  background-color: var(--color-success-10);
  color: var(--color-success-100);
}

.protocols-stats-container-element-item-value-diff.positive svg {
  transform: rotate(180deg);
}

.protocols-stats-container-element-item-value-diff svg {
  margin-right: 8px;
}

.protocols-stats-container-element-item-value-tooltip {
  grid-template-columns: 1fr 1fr;
  gap: 10px 24px;
  display: grid;
}

.protocols-stats-container-element-item-value-tooltip-content {
  letter-spacing: .02em;
  white-space: nowrap;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.protocols-stats-container-element-item-value-chains {
  flex-direction: row;
  align-items: center;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .protocols-stats-container-element-item-value-chains {
    margin-left: auto;
  }
}

.protocols-stats-container-element-item-value-chains-chain {
  background-color: var(--color-gray-900);
  border: 2px solid var(--color-gray-950);
  margin-right: -7px;
}

@media only screen and (width >= 1024px) {
  .protocols-stats-container-element-item-value-chains-chain {
    border: 2px solid var(--color-black);
  }
}

.protocols-stats-container-element-item-value-chains-chain-more {
  letter-spacing: .03em;
  background-color: var(--color-gray-800);
  color: var(--color-white);
  border-radius: 50%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
}

.protocols-stats-container-element-item-value-link {
  letter-spacing: .02em;
  color: var(--color-white);
  cursor: pointer;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
  text-underline-offset: 3px;
  background: none;
  border: none;
  flex-direction: row;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.protocols-stats-container-element-item-value-link:not(:disabled):hover {
  -webkit-text-decoration-color: var(--color-gray-400);
  text-decoration-color: var(--color-gray-400);
}

.protocols-stats-container-element-item-value-link:not(:disabled):active {
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
}

.protocols-stats-container-element-item-value-link:disabled {
  cursor: not-allowed;
  opacity: .5;
}

@keyframes loading-list {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-50%);
  }
}

.status-badge {
  max-width: max-content;
}

.status-badge-tooltip {
  text-align: center;
  max-width: 172px;
  font-size: 12px;
  line-height: normal;
}

.status-badge-status {
  text-transform: uppercase;
  min-width: max-content;
}

.status-badge-status.progress, .status-badge-status.governor, .status-badge-status.pending {
  background-color: var(--color-alert-10);
  color: var(--color-alert-100);
}

.status-badge-status.progress > svg > path, .status-badge-status.governor > svg > path, .status-badge-status.pending > svg > path {
  stroke-width: 1px;
}

.status-badge-status.completed {
  background-color: var(--color-success-10);
  color: var(--color-success-100);
}

.status-badge-status.completed > svg > path {
  stroke-width: 1px;
}

.status-badge-status.emitted {
  background-color: var(--color-information-12);
  color: var(--color-information-100);
}

.status-badge-status.emitted > svg > path {
  stroke-width: 1px;
}

.status-badge-small {
  border-radius: 40px;
  margin: auto;
}

.status-badge-small.progress > svg, .status-badge-small.governor > svg, .status-badge-small.pending > svg {
  color: var(--color-alert-100) !important;
}

.status-badge-small.completed > svg {
  color: var(--color-success-100) !important;
}

.status-badge-small.emitted > svg {
  color: var(--color-information-100) !important;
}

.terms-banner {
  background-color: var(--color-gray-900);
  z-index: 999;
  border-radius: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  gap: 8px;
  width: calc(100% - 16px);
  margin: 0 auto 8px;
  padding: 16px;
  display: flex;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
}

@media only screen and (width >= 1024px) {
  .terms-banner {
    justify-content: center;
    align-items: center;
    max-width: max-content;
    bottom: 8px;
  }
}

.terms-banner > svg {
  color: var(--color-gray-600);
  min-width: 24px;
}

.terms-banner-content {
  flex-direction: row;
  align-items: center;
  margin: auto 0;
  display: flex;
}

.terms-banner-content-text {
  letter-spacing: .02em;
  color: var(--color-gray-400);
  text-wrap: pretty;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.terms-banner-content-link {
  letter-spacing: .02em;
  color: var(--color-white);
  cursor: pointer;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
  text-underline-offset: 3px;
  background: none;
  border: none;
  flex-direction: row;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: inline;
}

.terms-banner-content-link:not(:disabled):hover {
  -webkit-text-decoration-color: var(--color-gray-400);
  text-decoration-color: var(--color-gray-400);
}

.terms-banner-content-link:not(:disabled):active {
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
}

.terms-banner-content-link:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.terms-banner-button {
  letter-spacing: .02em;
  background: var(--color-plum);
  color: var(--color-white);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background: var(--color-gray-900);
  background-color: var(--color-gray-800);
  border: none;
  border-radius: 27px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: auto;
  margin-left: 8px;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.terms-banner-button:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.terms-banner-button > svg {
  transition: transform .2s;
}

.terms-banner-button:not(:disabled):hover {
  background: var(--color-gray-800);
}

.terms-banner-button:not(:disabled):active {
  background: var(--color-gray-900);
}

.top-asset-list-row {
  height: 64px;
  color: var(--color-white);
  cursor: pointer;
  font-size: 14px;
  position: relative;
}

@media only screen and (width >= 1024px) {
  .top-asset-list-row {
    border-bottom: 1px solid #0000;
    font-size: 16px;
  }

  .top-asset-list-row:hover {
    background-color: var(--color-white-03);
  }
}

.top-asset-list-row-item {
  letter-spacing: -.03em;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.top-asset-list-row-item-to-asset {
  letter-spacing: .02em;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

@media only screen and (width >= 1024px) {
  .top-asset-list-row-item-chevron {
    display: none;
  }
}

.top-asset-list-row > td {
  text-align: end;
  height: 64px;
  padding: 0 8px;
  position: relative;
}

@media only screen and (width >= 768px) {
  .top-asset-list-row > td {
    padding: 0 16px;
  }
}

@media only screen and (width >= 1024px) {
  .top-asset-list-row > td {
    padding: 0 8px;
  }
}

@media only screen and (width >= 1440px) {
  .top-asset-list-row > td {
    padding: 0 16px;
  }
}

.top-asset-list-row > td:first-child > div {
  color: var(--color-gray-400);
  justify-content: center;
}

.top-asset-list-row > td:nth-child(2) > div {
  justify-content: flex-start;
}

.top-asset-list-row > td:nth-child(3) > div, .top-asset-list-row > td:nth-child(4) > div {
  justify-content: flex-end;
}

.top-asset-list-row > td > div {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .top-asset-list-row > td > div {
    gap: 12px;
  }
}

.top-asset-list-row > td > div > .image {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 2.4px;
  display: flex;
}

.top-asset-list-row > td > div > svg {
  color: var(--color-white-60);
  vertical-align: middle;
  transition: transform .2s;
}

@media only screen and (width >= 1024px) {
  .top-asset-list-row.active {
    background: linear-gradient(270deg, var(--color-plum-12) 65%, transparent 100%);
    border-bottom: 1px solid var(--color-white-05);
    background-attachment: fixed;
  }
}

.top-asset-list-row.active > td {
  z-index: 1;
}

.top-asset-list-row.active > td > div > svg {
  transform: rotate(-180deg);
}

.top-asset-list-row.active > td:before {
  background: var(--color-gray-950);
  border-bottom: 1px solid var(--color-gray-900);
  border-top: 1px solid var(--color-gray-900);
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.top-asset-list-row.active > td:first-child:before {
  background-color: var(--color-gray-950);
  border-left: 1px solid var(--color-gray-900);
  border-top: 1px solid var(--color-gray-900);
  content: "";
  z-index: -1;
  border-radius: 16px 0 0;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.top-asset-list-row.active > td:last-child:before {
  background-color: var(--color-gray-950);
  border-right: 1px solid var(--color-gray-900);
  border-top: 1px solid var(--color-gray-900);
  content: "";
  z-index: -1;
  border-radius: 0 16px 0 0;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media only screen and (width >= 1024px) {
  .top-asset-list-row.active > td:before, .top-asset-list-row.active > td:first-child:before, .top-asset-list-row.active > td:last-child:before {
    all: unset;
  }
}

.top-assets {
  flex-direction: column;
  gap: 24px;
  margin-bottom: 64px;
  display: flex;
  position: relative;
}

@media only screen and (width >= 1024px) {
  .top-assets {
    gap: 8px;
  }
}

.top-assets-subtitle {
  letter-spacing: .02em;
  color: var(--color-gray-600);
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 6px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.top-assets-loader {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.top-assets-header {
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  width: 100%;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .top-assets-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.top-assets-header-title {
  letter-spacing: .02em;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.top-assets-header-title > svg {
  color: var(--color-gray-600);
}

.top-assets-header-select-container {
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .top-assets-header-select-container {
    flex-direction: row;
    align-items: center;
    display: flex;
  }
}

.top-assets-header-select-container .select__control {
  justify-content: center;
}

.top-assets-body {
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  min-height: 512px;
  display: flex;
  position: relative;
}

@media only screen and (width >= 1024px) {
  .top-assets-body {
    min-height: 600px;
  }
}

.top-assets-body-table {
  border-collapse: collapse;
  flex: 1;
  width: 100%;
}

@media only screen and (width >= 1024px) {
  .top-assets-body-table {
    flex: .8;
    margin-bottom: auto;
  }
}

.top-assets-body-table > thead > tr > th {
  letter-spacing: .03em;
  color: var(--color-gray-400);
  text-align: end;
  white-space: nowrap;
  height: 64px;
  padding: 0 8px;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

@media only screen and (width >= 768px) {
  .top-assets-body-table > thead > tr > th {
    padding: 0 16px;
  }
}

@media only screen and (width >= 1024px) {
  .top-assets-body-table > thead > tr > th {
    padding: 0 8px;
  }
}

@media only screen and (width >= 1440px) {
  .top-assets-body-table > thead > tr > th {
    padding: 0 16px;
  }
}

.top-assets-body-table > thead > tr > th:first-child {
  text-align: center;
  width: 10%;
}

@media only screen and (width >= 768px) {
  .top-assets-body-table > thead > tr > th:first-child {
    width: 15%;
  }
}

@media only screen and (width >= 1024px) {
  .top-assets-body-table > thead > tr > th:first-child {
    width: 10%;
  }
}

@media (width >= 1900px) {
  .top-assets-body-table > thead > tr > th:first-child {
    width: 15%;
  }
}

.top-assets-body-table > thead > tr > th:nth-child(2) {
  text-align: start;
  width: 30%;
}

.top-assets-body-table > thead > tr > th:nth-child(3) {
  width: 30%;
}

@media only screen and (width >= 768px) {
  .top-assets-body-table > thead > tr > th:nth-child(3) {
    width: 20%;
  }
}

@media only screen and (width >= 1024px) {
  .top-assets-body-table > thead > tr > th:nth-child(3) {
    width: 30%;
  }
}

@media (width >= 1900px) {
  .top-assets-body-table > thead > tr > th:nth-child(3) {
    width: 20%;
  }
}

.top-assets-body-table > thead > tr > th:nth-child(4) {
  width: 20%;
}

@media only screen and (width >= 768px) {
  .top-assets-body-table > thead > tr > th:nth-child(4) {
    width: 25%;
  }
}

@media (width >= 1900px) {
  .top-assets-body-table > thead > tr > th:nth-child(4) {
    width: 35%;
  }
}

.top-assets-body-table > thead > tr > th:last-child {
  width: 10%;
}

@media only screen and (width >= 1024px) {
  .top-assets-body-table > thead > tr > th:last-child {
    display: none;
  }
}

.chart-container {
  background-color: var(--color-gray-950);
  border: 1px solid var(--color-gray-900);
  border-top: none;
  border-radius: 0 0 16px 16px;
  width: 100%;
  height: 390px;
  padding: 0 0 20px;
  position: relative;
}

@media only screen and (width >= 1024px) {
  .chart-container {
    height: unset;
    padding: unset;
    background-color: #0000;
    border: none;
    flex: 1.4;
    overflow: hidden;
  }
}

@media only screen and (width >= 1440px) {
  .chart-container {
    flex: 1.2;
  }
}

.chart-container > div:last-child {
  max-width: calc(100vw - 44px);
  min-height: 390px !important;
}

@media only screen and (width >= 768px) {
  .chart-container > div:last-child {
    max-width: calc(100vw - 132px);
  }
}

@media only screen and (width >= 1024px) {
  .chart-container > div:last-child {
    min-height: 600px !important;
  }
}

.chart-container .wormholescan-brand {
  filter: grayscale();
  height: 34px;
  min-height: unset;
  opacity: .2;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  z-index: 10;
  width: 217px;
  margin: auto;
  padding-top: 40px;
  position: absolute;
  inset: 0;
}

.chart-container-chain-icon img {
  width: 16px;
  height: 16px;
}

@media only screen and (width >= 768px) {
  .chart-container-chain-icon img {
    width: 20px;
    height: 20px;
  }
}

@media only screen and (width >= 1440px) {
  .chart-container-chain-icon img {
    width: 24px;
    height: 24px;
  }
}

.chart-container-tooltip {
  background-color: var(--color-gray-900);
  border-radius: 12px;
  flex-direction: column;
  gap: 12px;
  min-width: 192px;
  padding: 12px;
  list-style-type: none;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .chart-container-tooltip {
    min-width: 264px;
    padding: 16px;
  }
}

.chart-container-tooltip.transfers > .volume {
  order: 3;
}

.chart-container-tooltip.is-testnet > .volume {
  display: none;
}

.chart-container-tooltip-item {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  display: flex;
}

.chart-container-tooltip-item:first-child {
  justify-content: flex-start;
  margin-bottom: 4px;
}

.chart-container-tooltip-item-img {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.chart-container-tooltip-item-label {
  letter-spacing: .02em;
  color: var(--color-white);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.chart-container-tooltip-item-chain {
  letter-spacing: .02em;
  color: var(--color-gray-400);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.chart-container-tooltip-item-volume, .chart-container-tooltip-item-txs {
  letter-spacing: -.03em;
  color: var(--color-white);
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto Mono, Roboto, sans-serif !important;
}

.chart-container-xaxis-label {
  direction: rtl;
  display: none;
}

.wormholescan-brand {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.wormhole-stats {
  flex-direction: column;
  justify-content: center;
  min-height: 247px;
  margin-bottom: 40px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .wormhole-stats {
    min-height: 89px;
    margin-bottom: 60px;
  }
}

.wormhole-stats-loader {
  flex-direction: column;
  justify-content: center;
  max-height: 48px;
  margin: auto;
  display: flex;
  overflow: hidden;
}

.wormhole-stats-error {
  width: 100%;
}

.wormhole-stats-container {
  grid-template-columns: repeat(2, 1fr);
  gap: 36px 0;
  padding-bottom: 40px;
  display: grid;
  position: relative;
}

@media only screen and (width >= 1024px) {
  .wormhole-stats-container {
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    padding-bottom: 32px;
    display: flex;
  }
}

.wormhole-stats-container-item {
  flex-direction: column;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .wormhole-stats-container-item {
    align-items: flex-start;
  }
}

.wormhole-stats-container-item:nth-last-child(2) .wormhole-stats-container-item-title {
  display: none;
}

@media only screen and (width >= 1024px) {
  .wormhole-stats-container-item:nth-last-child(2) .wormhole-stats-container-item-title {
    display: block;
  }
}

.wormhole-stats-container-item-title {
  letter-spacing: .03em;
  color: var(--color-gray-400);
  text-transform: uppercase;
  min-width: max-content;
  height: 18px;
  margin-bottom: 8px;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.wormhole-stats-container-item-title-time {
  letter-spacing: .03em;
  color: var(--color-gray-200);
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.wormhole-stats-container-item-title-time-arrow {
  vertical-align: -8px;
  margin-left: 4px;
}

.wormhole-stats-container-item-title-time-select {
  width: auto;
  display: inline-block;
}

.wormhole-stats-container-item-title-time-select .select__control {
  letter-spacing: .03em;
  color: var(--color-gray-200);
  background-color: #0000;
  height: auto;
  min-height: auto;
  padding: 0;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.wormhole-stats-container-item-title-time-select .select__control:hover {
  background-color: #0000;
}

.wormhole-stats-container-item-title-time-select .select__menu-list {
  text-transform: capitalize;
}

.wormhole-stats-container-item-title-time-select .select__indicators {
  display: none;
}

.wormhole-stats-container-item-title-icon {
  vertical-align: -8px;
  margin-left: 8px;
  display: inline-block;
}

.wormhole-stats-container-item-value {
  color: var(--color-white);
  letter-spacing: -.06em;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 20px;
  font-weight: 400;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .wormhole-stats-container-item-value {
    font-size: 24px;
  }
}

.wormhole-stats-container-item-value-flip {
  flex-direction: row;
  align-items: center;
  gap: 3px;
  width: 180px;
  margin-top: 2px;
  display: flex;
  transform: scale(.8)translateX(-12%);
}

@media only screen and (width >= 520px) {
  .wormhole-stats-container-item-value-flip {
    width: auto;
    transform: none;
  }
}

.wormhole-stats-container-item-value-flip-dollar {
  transform: translateY(1px);
}

.wormhole-stats-container-item-value-link {
  letter-spacing: .02em;
  color: var(--color-white-60);
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  max-width: max-content;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.wormhole-stats-container-item-value-link:hover {
  color: var(--color-white);
}

.wormhole-stats-container-line {
  background-color: var(--color-gray-900);
  width: calc(100% + 32px);
  height: 1px;
  position: absolute;
  bottom: 0;
  left: -16px;
}

@media only screen and (width >= 768px) {
  .wormhole-stats-container-line {
    width: calc(100% + 120px);
    left: -60px;
  }
}

@media only screen and (width >= 1024px) {
  .wormhole-stats-container-line {
    width: calc(100% + 240px);
    left: -120px;
  }
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-offset: 16px;
  --toastify-toast-top: max(var(--toastify-toast-offset), env(safe-area-inset-top));
  --toastify-toast-right: max(var(--toastify-toast-offset), env(safe-area-inset-right));
  --toastify-toast-left: max(var(--toastify-toast-offset), env(safe-area-inset-left));
  --toastify-toast-bottom: max(var(--toastify-toast-offset), env(safe-area-inset-bottom));
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-toast-bd-radius: 6px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
  --toastify-color-progress-bgo: .2;
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: var(--toastify-toast-top);
  left: var(--toastify-toast-left);
}

.Toastify__toast-container--top-center {
  top: var(--toastify-toast-top);
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: var(--toastify-toast-top);
  right: var(--toastify-toast-right);
}

.Toastify__toast-container--bottom-left {
  bottom: var(--toastify-toast-bottom);
  left: var(--toastify-toast-left);
}

.Toastify__toast-container--bottom-center {
  bottom: var(--toastify-toast-bottom);
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: var(--toastify-toast-bottom);
  right: var(--toastify-toast-right);
}

@media only screen and (width <= 480px) {
  .Toastify__toast-container {
    width: 100vw;
    left: env(safe-area-inset-left);
    margin: 0;
    padding: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: env(safe-area-inset-top);
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: env(safe-area-inset-bottom);
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: env(safe-area-inset-right);
    left: initial;
  }
}

.Toastify__toast {
  --y: 0;
  -ms-touch-action: none;
  touch-action: none;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  border-radius: var(--toastify-toast-bd-radius);
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  z-index: 0;
  direction: ltr;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 12px #0000001a;
}

.Toastify__toast--stacked {
  width: 100%;
  transform: translate3d(0, var(--y), 0) scale(var(--s));
  transition: transform .3s;
  position: absolute;
}

.Toastify__toast--stacked[data-collapsed] .Toastify__toast-body, .Toastify__toast--stacked[data-collapsed] .Toastify__close-button {
  transition: opacity .1s;
}

.Toastify__toast--stacked[data-collapsed="false"] {
  overflow: visible;
}

.Toastify__toast--stacked[data-collapsed="true"]:not(:last-child) > * {
  opacity: 0;
}

.Toastify__toast--stacked:after {
  content: "";
  height: calc(var(--g) * 1px);
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
}

.Toastify__toast--stacked[data-pos="top"] {
  top: 0;
}

.Toastify__toast--stacked[data-pos="bot"] {
  bottom: 0;
}

.Toastify__toast--stacked[data-pos="bot"].Toastify__toast--stacked:before {
  transform-origin: top;
}

.Toastify__toast--stacked[data-pos="top"].Toastify__toast--stacked:before {
  transform-origin: bottom;
}

.Toastify__toast--stacked:before {
  content: "";
  z-index: -1;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scaleY(3);
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  flex-shrink: 0;
  width: 20px;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .5s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (width <= 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  z-index: 1;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  width: 14px;
  height: 16px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 100%;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
  border-bottom-left-radius: initial;
  border-bottom-right-radius: var(--toastify-toast-bd-radius);
}

.Toastify__progress-bar--wrp {
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--wrp[data-hidden="true"] {
  opacity: 0;
}

.Toastify__progress-bar--bg {
  opacity: var(--toastify-color-progress-bgo);
  width: 100%;
  height: 100%;
}

.Toastify__spinner {
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, var(--y), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, var(--y), 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, var(--y), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, var(--y), 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, calc(var(--y)  - 10px), 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y)  + 20px), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, calc(var(--y)  - 10px), 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y)  + 20px), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: translate3d(0, var(--y), 0) scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: translate3d(0, var(--y), 0) perspective(400px);
  }

  30% {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, var(--y), 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, var(--y), 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.only-mainnet {
  margin-bottom: 80px;
}

.only-mainnet-text {
  letter-spacing: -.03em;
  color: var(--color-gray-200);
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.only-mainnet-text-link {
  letter-spacing: .02em;
  color: var(--color-coral);
  cursor: pointer;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-gray-600);
  text-decoration-color: var(--color-gray-600);
  text-underline-offset: 3px;
  letter-spacing: -.03em;
  -webkit-text-decoration-color: var(--color-coral-20);
  text-decoration-color: var(--color-coral-20);
  background: none;
  border: none;
  flex-direction: row;
  align-items: center;
  font-family: Roboto Mono, Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: inline-block;
}

.only-mainnet-text-link:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.only-mainnet-text-link:not(:disabled):hover {
  -webkit-text-decoration-color: var(--color-coral-40);
  text-decoration-color: var(--color-coral-40);
}

.only-mainnet-text-link:not(:disabled):active {
  -webkit-text-decoration-color: var(--color-coral-20);
  text-decoration-color: var(--color-coral-20);
}

.error-page {
  justify-content: center;
  margin: auto 0;
  padding: 32px 0;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .error-page {
    padding: 96px 32px;
  }

  .error-page-bg-503 {
    background-image: url("bg-503.86dafd1a.svg");
    background-position: calc(50% - 136px);
    background-repeat: no-repeat;
  }

  .error-page-bg-general {
    background-image: url("bg-gerenal.efdc4c76.svg");
    background-position: 50% 0;
    background-repeat: no-repeat;
  }
}

@media only screen and (width >= 1440px) {
  .error-page {
    padding: 96px 200px;
  }
}

.error-page-bg-500 {
  background-image: url("bg-mobile-500.da936b7a.svg");
  background-position: calc(50% + 116px) calc(50% - 136px);
  background-repeat: no-repeat;
}

@media only screen and (width >= 1024px) {
  .error-page-bg-500 {
    background-image: url("bg-500.8e29e1b7.svg");
    background-position: center;
  }
}

.error-page-container {
  background-color: var(--color-white-05);
  border-radius: 10px;
  grid-template-columns: 1fr;
  place-items: center;
  width: 100%;
  padding: 32px 24px;
  display: grid;
}

@media only screen and (width >= 1024px) {
  .error-page-container {
    background-color: unset;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr 1fr;
    place-items: start;
    max-width: 1100px;
    padding: 0;
  }
}

.error-page-container-title {
  text-align: center;
}

@media only screen and (width >= 1024px) {
  .error-page-container-title {
    text-align: left;
    font-size: 40px;
  }

  .error-page-container-title-400 {
    font-weight: 600;
  }
}

.error-page-container-title span {
  display: block;
}

.error-page-container-image {
  grid-row: span 2;
  margin: 40px auto 0;
  position: relative;
}

@media only screen and (width >= 1024px) {
  .error-page-container-image {
    margin: auto 0 auto auto;
  }
}

.error-page-container-image-text {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 24px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (width >= 1024px) {
  .error-page-container-image-text {
    font-size: 32px;
  }
}

.error-page-container-image-text span {
  font-size: 40px;
  display: block;
}

.error-page-container-image-astronaut {
  width: 140px;
  margin: auto;
  position: absolute;
  inset: 0;
}

@media only screen and (width >= 1024px) {
  .error-page-container-image-astronaut {
    width: 200px;
    top: 80px;
    right: 64px;
  }
}

.error-page-container-image-400 {
  width: 156px;
}

@media only screen and (width >= 1024px) {
  .error-page-container-image-400 {
    width: 285px;
  }
}

.error-page-container-image-500 {
  width: 174px;
}

@media only screen and (width >= 1024px) {
  .error-page-container-image-500 {
    width: 425px;
  }
}

.error-page-container-image-502 {
  width: 174px;
}

@media only screen and (width >= 1024px) {
  .error-page-container-image-502 {
    width: 312px;
  }
}

.error-page-container-image-503 {
  width: 200px;
}

@media only screen and (width >= 1024px) {
  .error-page-container-image-503 {
    width: 425px;
  }
}

.error-page-container-image-general {
  width: 147px;
}

@media only screen and (width >= 1024px) {
  .error-page-container-image-general {
    width: 341px;
  }
}

.error-page-container-body {
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  margin-top: 36px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .error-page-container-body {
    text-align: left;
    margin-top: 16px;
  }
}

.error-page-container-body-description {
  max-width: 324px;
}

@media only screen and (width >= 1024px) {
  .error-page-container-body-description {
    max-width: 424px;
    font-size: 20px;
  }
}

.error-page-container-body-description-400 {
  font-size: 16px;
}

@media only screen and (width >= 1024px) {
  .error-page-container-body-description-400 {
    margin-top: 20px;
  }
}

.error-page-container-body-description-500 {
  color: var(--color-plum);
}

.error-page-container-body-description span {
  word-break: break-all;
  display: block;
}

.error-page-container-body-description em {
  font-style: normal;
}

@media only screen and (width >= 1024px) {
  .error-page-container-body-description-mobile {
    display: none;
  }
}

.error-page-container-body-description-desktop {
  display: none;
}

@media only screen and (width >= 1024px) {
  .error-page-container-body-description-desktop {
    display: inline-block;
  }
}

.error-page-container-body-description a {
  color: var(--color-lime);
  text-decoration: none;
}

.error-page-container-body-description a:hover {
  text-decoration: underline;
}

.error-page-container-body-description .info-link {
  color: var(--color-lime);
  text-decoration: none;
}

.error-page-container-body-description .info-link:hover {
  text-decoration: underline;
}

.error-page-container-body-code {
  color: var(--color-plum-12);
  margin-top: 16px;
  font-size: 20px;
}

@media only screen and (width >= 1024px) {
  .error-page-container-body-code {
    margin-top: 42px;
  }
}

.error-page-container-body-button {
  letter-spacing: .02em;
  background: var(--color-plum);
  color: var(--color-white);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background: var(--color-gray-900);
  border: none;
  border-radius: 27px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  height: 36px;
  margin: 20px auto 0;
  padding: 0 32px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.error-page-container-body-button:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.error-page-container-body-button > svg {
  transition: transform .2s;
}

.error-page-container-body-button:not(:disabled):hover {
  background: var(--color-gray-800);
}

.error-page-container-body-button:not(:disabled):active {
  background: var(--color-gray-900);
}

@media only screen and (width >= 1024px) {
  .error-page-container-body-button {
    margin: 32px auto 0 0;
    padding-left: 56px;
    padding-right: 56px;
  }
}
/*# sourceMappingURL=index.feefdfae.css.map */
